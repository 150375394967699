import React from "react";
import { FlashMessages } from "./flash-messages";
import {
  IAddAction,
  IContextValue,
  TFlashMessageContent,
  IRemoveAction,
  IFlashMessagesState,
} from "./flash-messages.types";

const reducer = (
  state: IFlashMessagesState,
  action: IAddAction | IRemoveAction
): IFlashMessagesState => {
  switch (action.type) {
    case "add":
      return {
        ...state,
        messages: [
          ...state.messages,
          {
            id: state.nextId,
            content: action.content,
          },
        ],
        nextId: state.nextId + 1,
      };
    case "remove":
      return {
        ...state,
        messages: state.messages.filter((message) => message.id !== action.id),
      };
    default:
      return state;
  }
};

const initialState: IFlashMessagesState = {
  messages: [],
  nextId: 1,
};

const FlashMessagesContext = React.createContext<IContextValue | null>(null);

export function FlashMessagesProvider({ children }: React.PropsWithChildren) {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  const addMessage = React.useCallback(
    (content: TFlashMessageContent) => {
      dispatch({ type: "add", content });
    },
    [dispatch]
  );

  const removeMessage = React.useCallback(
    (id: number) => dispatch({ type: "remove", id }),
    [dispatch]
  );

  return (
    <FlashMessagesContext.Provider
      value={{
        messages: state.messages,
        addMessage,
        removeMessage,
      }}
    >
      {children}
      <FlashMessages />
    </FlashMessagesContext.Provider>
  );
}

export const useFlashMessages = (): IContextValue => {
  const value = React.useContext(FlashMessagesContext);

  if (value == null) {
    throw new Error("Flash message provider is missing");
  }

  return value;
};
