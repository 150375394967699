import React from "react";
import { DSButton } from "@hundred5/design-system";
import { useFlashMessages, useCopyToClipboard } from "@/features/common";
import { Icon } from "../icon";
import { faLinkSimple } from "@fortawesome/pro-regular-svg-icons";

interface CopyClickboardButtonProps {
  linkToCopy: string;
  tooltip?: string;
  disabled?: boolean;
  disabledTooltip?: string;
  onClick?: () => void;
}

export function CopyClipboardButton({
  linkToCopy,
  tooltip,
  disabled,
  disabledTooltip,
  onClick,
}: CopyClickboardButtonProps) {
  const { addMessage } = useFlashMessages();
  const [_copiedText, copyToClipboard] = useCopyToClipboard();

  return (
    <DSButton
      variant="secondary"
      data-rh={disabled ? disabledTooltip : tooltip}
      onClick={async (e) => {
        e.stopPropagation();

        if ((await copyToClipboard(linkToCopy)) === true) {
          addMessage({ type: "link_copied" });
        }

        if (onClick) onClick();
      }}
      disabled={disabled}
      Icon={<Icon icon={faLinkSimple} color="purple-100" fontSize="12px" />}
    />
  );
}
