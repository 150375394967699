import React from "react";
import styled from "@emotion/styled";

export function EmptyItem() {
  return <EmptyItemContainer>No applications</EmptyItemContainer>;
}

const EmptyItemContainer = styled.li`
  font-weight: 400;
  font-size: 10px;
  color: ${(props) => props.theme.colors.purple[60]};
  padding: 0 16px;
`;
