import { createContext, useContext, useState } from "react";

type TWizardContext<T> = {
  data: T;
  stepCount: number;
  currentStep: number;
  setData: (data: T) => void;
  goBack: () => void;
  goForward: () => void;
  onSubmit: (data: T) => Promise<boolean>;
  onComplete?: () => void;
};

const WizardContext = createContext<TWizardContext<any>>({
  data: {},
  stepCount: 0,
  currentStep: 0,
  setData: () => {},
  goBack: () => {},
  goForward: () => {},
  onSubmit: () => Promise.resolve(true),
  onComplete: () => {},
});

export function useWizard<T>() {
  return useContext<TWizardContext<T>>(WizardContext);
}

export function WizardProvider<T>({
  stepCount,
  initalData,
  onSubmit,
  onComplete,
  children,
}: {
  stepCount: number;
  initalData: T;
  onSubmit: (data: T) => Promise<boolean>;
  onComplete?: () => void;
  children: React.ReactNode;
}) {
  const [data, setData] = useState<T>(initalData);
  const [currentStep, setCurrentStep] = useState(0);

  const goBack = () => {
    if (currentStep === 0) {
      return;
    }
    setCurrentStep(currentStep - 1);
  };

  const goForward = () => {
    if (currentStep === stepCount) {
      return;
    }
    setCurrentStep(currentStep + 1);
  };

  return (
    <WizardContext.Provider
      value={{
        data,
        stepCount,
        currentStep,
        setData,
        goBack,
        goForward,
        onSubmit,
        onComplete,
      }}
    >
      {children}
    </WizardContext.Provider>
  );
}

function WizardProviderStep({
  index,
  children,
}: {
  index: number;
  children: React.ReactNode;
}) {
  const { currentStep } = useWizard();

  if (currentStep === index) {
    return <>{children}</>;
  }

  return null;
}

WizardProvider.Step = WizardProviderStep;
