import React, { useEffect } from "react";
import styled from "@emotion/styled";
import { faCircleInfo } from "@fortawesome/pro-regular-svg-icons";
import { device } from "@/styles/breakpoints";
import { useSkillProfileQuery } from "@/generated/graphql";
import { useApiErrorHandler } from "@/features/error";
import { Icon, Loader } from "@/features/common";
import { SkillProfileProgress } from "@/features/skill";
import { EmptyList, SkillListItem } from "./ui";

export function SkillProfile() {
  const handleError = useApiErrorHandler();
  const [{ data, fetching, error }] = useSkillProfileQuery();

  useEffect(() => {
    if (!error) return;
    handleError(error);
  }, [handleError, error]);

  return (
    <>
      <Title>
        Your Skill Profile
        <Icon
          icon={faCircleInfo}
          color="purple-60"
          fontSize="14px"
          data-rh-at="right"
          data-rh="You can take each skill test a maximum of three times, with a 30-day waiting period between each attempt."
        />
      </Title>

      <Description>
        Build your skills profile by adding new or improving existing skills.
      </Description>

      <SkillListContainer>
        <SkillProfileProgress />

        <SkillList setMinHeight={fetching}>
          {fetching ? (
            <Loader absolute />
          ) : !data?.me.skills?.length ? (
            <EmptyList />
          ) : (
            data?.me.skills?.map(
              (skill) =>
                !!skill && (
                  <SkillListItem
                    key={skill.id}
                    skill={skill}
                    candidateSlug={data.me.slug}
                  />
                )
            )
          )}
        </SkillList>
      </SkillListContainer>
    </>
  );
}

const Title = styled.div`
  font-weight: 700;
  font-size: 22px;
  color: ${(props) => props.theme.typography.colorPrimary};
  display: flex;
  align-items: center;
  gap: 8px;

  @media ${device.tablet} {
    font-size: 20px;
    svg {
      display: none;
    }
  }
`;

const Description = styled.div`
  font-weight: 400;
  font-size: 14px;
  color: ${(props) => props.theme.colors.purple[70]};
  margin-top: 8px;

  @media ${device.tablet} {
    display: none;
  }
`;

const SkillListContainer = styled.div`
  background: ${(props) => props.theme.colors.white};
  box-shadow: ${(props) => props.theme.shadow.card};
  border-radius: 8px;
  padding: 16px;
  margin-top: 16px;
`;

const SkillList = styled.div<{ setMinHeight: boolean }>`
  position: relative;
  margin-top: 24px;
  min-height: ${(props) => (props.setMinHeight ? "104px" : "unset")};
`;
