import { formatDistanceToNowStrict } from "date-fns";
import formatDate from "date-fns/format";
import { formatDistance } from "./timestamp.local";

export function formatDistanceToNow(
  timestamp: string | null,
  addSuffix: boolean
) {
  if (!timestamp) return "";
  return formatDistanceToNowStrict(new Date(timestamp), {
    addSuffix,
    locale: {
      formatDistance: formatDistance,
    },
  });
}

export function formatTimestamp(timestamp: string | null) {
  if (!timestamp) return "";
  return formatDate(new Date(timestamp), "MMMM d, yyyy HH:mm");
}
