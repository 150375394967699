import React from "react";
import styled from "@emotion/styled";
import * as Sentry from "@sentry/browser";
import { Button } from "@hundred5/design-system";
import { ErrorPage } from "@/features/error";

export function ErrorHandler() {
  const supportEmail = process.env.NEXT_PUBLIC_SUPPORT_EMAIL;

  return (
    <ErrorPage
      message={
        <p>
          Something went wrong. Please reload the page or contact us at{" "}
          <a href={`mailto:${supportEmail}`}>{supportEmail}</a>
        </p>
      }
      content={
        <Content>
          <Button onClick={() => Sentry.showReportDialog()}>
            Send feedback
          </Button>
          <Button
            onClick={() => {
              window.location.href = "/";
            }}
          >
            Reload app
          </Button>
        </Content>
      }
    />
  );
}

//region Styles
const Content = styled.div`
  display: flex;
  justify-content: center;

  button:first-child {
    margin-right: 10px;
  }
`;
//endregion
