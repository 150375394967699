import styled from "@emotion/styled";

export const IconButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-appearance: none;
  cursor: pointer;
  outline: none;
  border: none;
  background: none;
  width: 100%;
  height: 32px;
  padding: 0;
  transition: background 0.1s;

  &:hover {
    background: ${(props) => props.theme.colors.purple[100]};
  }
`;
