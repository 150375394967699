import { SeniorityLevel } from "@/generated/graphql";

const seniorityOrder: SeniorityLevel[] = [
  SeniorityLevel.Internship,
  SeniorityLevel.EntryLevel,
  SeniorityLevel.Junior,
  SeniorityLevel.MidLevel,
  SeniorityLevel.Senior,
  SeniorityLevel.Expert,
];

interface SeniorityLevelItem {
  id: SeniorityLevel;
  label: string;
}

export const getJobOfferSeniorityList = (): SeniorityLevelItem[] => {
  return seniorityOrder.map((seniority) => ({
    id: seniority,
    label: getJobOfferSeniorityLabel(seniority),
  }));
};

export const getJobOfferSeniorityLabel = (seniority: SeniorityLevel) => {
  switch (seniority) {
    case SeniorityLevel.Internship:
      return "Internships";
    case SeniorityLevel.EntryLevel:
      return "Entry-level/ graduate";
    case SeniorityLevel.Junior:
      return "Junior (1-2 years)";
    case SeniorityLevel.MidLevel:
      return "Mid-level (3-4 years)";
    case SeniorityLevel.Senior:
      return "Senior (5-8 years)";
    case SeniorityLevel.Expert:
      return "Expert & Leadership (9+ years)";
  }
};

export const sortSeniorityLevels = (
  values: SeniorityLevel[]
): SeniorityLevel[] => {
  return values.sort((a, b) => {
    return seniorityOrder.indexOf(a) - seniorityOrder.indexOf(b);
  });
};
