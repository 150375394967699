import { CombinedError } from "urql";

export const hasGraphQLError = (
  error: CombinedError,
  type: string,
  message?: string
): boolean => {
  return error.graphQLErrors.some(
    (e) =>
      e.extensions?.type === type &&
      (message ? e.message.includes(message) : true)
  );
};
