import React from "react";
import styled from "@emotion/styled";
import { useColor } from "@/features/common";

interface ProgressBarProps extends React.HTMLAttributes<HTMLDivElement> {
  /**
   * Percentual value of how much the progress bar is filled out of 100%
   */
  value?: number;
  /**
   * Color of the filled progress bar.
   * If color from theme is used, then use format -> $name-$value
   */
  color?: string;
  /**
   * Color of the unfilled (background) progress bar.
   * If color from theme is used, then use format -> $name-$value
   */
  backgroundColor?: string;
  /**
   * Height of the progress bar, default - 8px
   */
  height?: string;
  /**
   * Border radius of the progress bar, default - 8px
   */
  borderRadius?: string;
}

export function ProgressBar({
  value = 0,
  color,
  backgroundColor,
  height = "8px",
  borderRadius = "8px",
  ...rest
}: ProgressBarProps) {
  return (
    <Background
      height={height}
      backgroundColor={useColor(backgroundColor, "purple-10")}
      borderRadius={borderRadius}
      {...rest}
    >
      <Bar value={value} color={useColor(color, "purple-100")}></Bar>
    </Background>
  );
}

const Background = styled.div<{
  height: string;
  backgroundColor: string;
  borderRadius: string;
}>`
  height: ${(props) => props.height};
  width: 100%;
  border-radius: ${(props) => props.borderRadius};
  overflow: hidden;
  background: ${(props) => props.backgroundColor};
`;

const Bar = styled.div<{ value: number; color: string }>`
  height: 100%;
  width: ${(props) => props.value}%;
  background: ${(props) => props.color};
  transition: width 0.4s ease-out;
`;
