import React from "react";
import styled from "@emotion/styled";
import { DSButton } from "@hundred5/design-system";
import { device } from "@/styles/breakpoints";
import { EditButton } from "@/features/common";

interface EnabledProps {
  onEdit: () => void;
  onDisable: () => void;
  children: React.ReactNode;
}

export function Enabled({ onEdit, onDisable, children }: EnabledProps) {
  return (
    <EnabledContainer>
      <Title>Job Offers</Title>
      <Actions>
        <DSButton variant="secondary" onClick={onDisable}>
          Disable
        </DSButton>
        <EditButton onClick={onEdit} />
      </Actions>
      <Separator />
      <Preferences>{children}</Preferences>
    </EnabledContainer>
  );
}

const EnabledContainer = styled.div`
  background-color: ${(props) => props.theme.colors.white};
  border-radius: 8px;
  padding: 24px 36px 36px 36px;
  display: grid;
  grid-template-areas: "title actions" "separator separator" "preferences preferences";
  grid-auto-columns: auto min-content;
  gap: 16px;

  @media ${device.tablet} {
    border-radius: unset;
    padding: 16px;
  }
`;

const Title = styled.div`
  grid-area: title;
  place-self: flex-end flex-start;
  font-size: 16px;
  font-weight: 700;
  color: ${(props) => props.theme.typography.colorPrimary};
`;

const Actions = styled.div`
  grid-area: actions;
  display: flex;
  gap: 8px;
  justify-content: flex-end;
`;

const Separator = styled.hr`
  grid-area: separator;
  border: none;
  border-top: 1px solid ${(props) => props.theme.colors.purple[10]};
  height: 1px;
  margin: 0;
`;

const Preferences = styled.div`
  grid-area: preferences;
`;
