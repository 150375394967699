import React from "react";
import { useRouter } from "next/router";
import { useMedia } from "react-use";
import { useLayout } from "@/features/layout";
import { Icon } from "@/features/common";
import { faUser } from "@fortawesome/pro-regular-svg-icons";
import { faUser as faUserSolid } from "@fortawesome/pro-solid-svg-icons";
import { device } from "@/styles/breakpoints";
import { IconButton } from "./icon-button";

interface UserIconButtonProps {
  slug?: string;
}

export function UserIconButton({ slug }: UserIconButtonProps) {
  const router = useRouter();
  const isTablet = useMedia(device.tablet);
  const { setSidebarCollapsed } = useLayout();

  const handleClick = () => {
    if (isTablet) setSidebarCollapsed(true);
    router.push(`/${slug}`);
  };

  return (
    <IconButton
      data-sidebar-tooltip="My profile"
      data-sidebar-tooltip-at="right"
      onClick={handleClick}
    >
      <Icon
        icon={router.pathname === "/[id]" ? faUserSolid : faUser}
        color="purple-40"
        fontSize="16px"
      />
    </IconButton>
  );
}
