import { faDollar, faEur, faGbp } from "@fortawesome/pro-regular-svg-icons";
import { Icon } from "@/features/common";
import { SalaryCurrency } from "@/generated/graphql";

interface SalaryCurrencyItem {
  id: SalaryCurrency;
  label: React.ReactNode;
}

export const jobOfferSalaryCurrencyList: SalaryCurrencyItem[] = [
  {
    id: SalaryCurrency.Usd,
    label: (
      <>
        <Icon icon={faDollar} color="inherit" fixedWidth />
        USD
      </>
    ),
  },
  {
    id: SalaryCurrency.Eur,
    label: (
      <>
        <Icon icon={faEur} color="inherit" fixedWidth />
        EUR
      </>
    ),
  },
  {
    id: SalaryCurrency.Gbp,
    label: (
      <>
        <Icon icon={faGbp} color="inherit" fixedWidth />
        GBP
      </>
    ),
  },
];
