import React, { useEffect, useMemo, useState } from "react";
import styled from "@emotion/styled";
import { device } from "@/styles/breakpoints";
import { Assessment, useSkillAssessmentsQuery } from "@/generated/graphql";
import { useApiErrorHandler } from "@/features/error";
import { Loader, TabsWithCarousel } from "@/features/common";
import { SkillAssessmentStartButton } from "@/features/skill";

export function SkillAssessments() {
  const handleError = useApiErrorHandler();
  const [activeCategory, setActiveCategory] = useState("");
  const [{ data, fetching, error }] = useSkillAssessmentsQuery({
    variables: { isDone: null },
  });

  useEffect(() => {
    if (!error) return;
    handleError(error);
  }, [handleError, error]);

  const categorisedAssessments = useMemo(() => {
    return (
      data?.assessments?.reduce(
        (result: { [category: string]: Assessment[] }, item: Assessment) => {
          if (result[item.category]) {
            result[item.category].push(item);
          } else {
            result[item.category] = [item];
          }
          return result;
        },
        {}
      ) ?? {}
    );
  }, [data?.assessments]);

  useEffect(
    () => setActiveCategory(Object.keys(categorisedAssessments)[0]),
    [categorisedAssessments]
  );

  return (
    <AssessmentListContainer>
      {fetching ? (
        <Loader absolute />
      ) : (
        <>
          <Tabs>
            <TabsWithCarousel
              tabs={Object.keys(categorisedAssessments)}
              activeTab={activeCategory}
              onTabClick={setActiveCategory}
              slideStep={60}
            />
          </Tabs>

          <List>
            {categorisedAssessments[activeCategory]?.map((assesment) => (
              <ListItem key={assesment.skillId}>
                <Name>{assesment.skillName}</Name>
                <Questions>{assesment.questionCount} questions</Questions>
                <Duration>
                  {Math.floor(assesment.duration / 60)} minutes
                </Duration>
                <Action>
                  {assesment.done ? (
                    <Description>Test taken</Description>
                  ) : (
                    <SkillAssessmentStartButton
                      openingSlug={assesment.openingSlug}
                      testSlug={assesment.testSlug}
                      skillId={assesment.skillId}
                      skillName={assesment.skillName}
                    />
                  )}
                </Action>
              </ListItem>
            ))}
          </List>
        </>
      )}
    </AssessmentListContainer>
  );
}

const AssessmentListContainer = styled.div`
  position: relative;
  min-height: 104px;
`;

const Tabs = styled.div`
  position: sticky;
  top: 0;
  padding: 16px 36px 24px 36px;
  background-color: ${(props) => props.theme.colors.white};
  z-index: 1;

  @media ${device.tablet} {
    padding: 16px;
  }
`;

const List = styled.div`
  padding: 0 36px;
  background-color: ${(props) => props.theme.colors.white};

  @media ${device.tablet} {
    padding: 0 16px;
  }
`;

const ListItem = styled.div`
  display: grid;
  grid-template-areas: "name questions duration action";
  grid-auto-columns: minmax(0, 1fr);
  grid-auto-rows: min-content;
  grid-column-gap: 20px;
  grid-row-gap: 4px;
  align-content: center;
  border-bottom: 1px solid ${(props) => props.theme.colors.purple[10]};
  padding: 16px 16px 16px 0;
  font-weight: 400;

  &:last-of-type {
    border-bottom: none;
  }

  @media ${device.tablet} {
    grid-template-areas: "name name action" "questions duration action";
    grid-auto-columns: auto minmax(0, 1fr);
    padding: 16px 8px 16px 0;
  }
`;

const Name = styled.div`
  grid-area: name;
  align-self: center;
  font-size: 14px;
  color: ${(props) => props.theme.typography.colorPrimary};
`;

const Questions = styled.div`
  grid-area: questions;
  justify-self: flex-end;
  align-self: center;
  font-size: 14px;
  color: ${(props) => props.theme.typography.colorSecondary};

  @media ${device.tablet} {
    justify-self: flex-start;
    font-size: 12px;
  }
`;

const Duration = styled.div`
  grid-area: duration;
  justify-self: flex-end;
  align-self: center;
  font-size: 14px;
  color: ${(props) => props.theme.typography.colorSecondary};

  @media ${device.tablet} {
    justify-self: flex-start;
    font-size: 12px;
  }
`;

const Action = styled.div`
  grid-area: action;
  align-self: center;
  justify-self: flex-end;
  margin: -6px 0;
`;

const Description = styled.span`
  font-style: italic;
  font-weight: 400;
  font-size: 12px;
  color: ${(props) => props.theme.typography.colorSecondary};
`;
