import React from "react";
import styled from "@emotion/styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/pro-solid-svg-icons";

export function Example() {
  return (
    <ExampleContainer>
      Example component <FontAwesomeIcon icon={faStar} />
    </ExampleContainer>
  );
}

const ExampleContainer = styled.div`
  background-color: red;
`;
