import React from "react";
import styled from "@emotion/styled";
import { ProgressBar, useWizard } from "@/features/common";

export function WizardProgress() {
  const { currentStep, stepCount } = useWizard();

  return (
    <ProgressBarContainer>
      <ProgressBar
        value={(100 / stepCount) * currentStep}
        color="green-100"
        borderRadius="0"
      />
    </ProgressBarContainer>
  );
}

const ProgressBarContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 9999;
`;
