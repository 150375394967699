import React, { useEffect, useMemo, useState } from "react";
import styled from "@emotion/styled";
import { useMedia } from "react-use";
import { DuckIcon, Icon, ProgressCircle } from "@/features/common";
import { useApiErrorHandler } from "@/features/error";
import { DSButton } from "@hundred5/design-system";
import { device } from "@/styles/breakpoints";
import { useSkillProfileProgressQuery } from "@/generated/graphql";
import { faPlus } from "@fortawesome/pro-regular-svg-icons";
import { SkillAssessmentAddModal } from "@/features/skill";

export function SkillProfileProgress() {
  const [openSkillAssessmentModal, setOpenSkillAssessmentModal] =
    useState(false);
  const handleError = useApiErrorHandler();
  const isTablet = useMedia(device.tablet);

  const [{ data, error, fetching, stale }] = useSkillProfileProgressQuery();
  const loading = fetching || stale;

  useEffect(() => {
    if (!error) return;
    handleError(error);
  }, [handleError, error]);

  const nrOfProgress = useMemo(() => {
    return (
      data?.me.skills?.reduce(
        (total, item) => (item.isAssessed ? total + 1 : total),
        0
      ) || 0
    );
  }, [data?.me.skills]);

  return (
    <SkillProfileProgressContainer>
      <ContentWrapper>
        {nrOfProgress > 2 ? (
          <ImageWrapper>
            <DuckIcon width={30} />
          </ImageWrapper>
        ) : (
          <ProgressCircle
            isRotated={true}
            value={nrOfProgress * 33.3333}
            color={"orange-100"}
            size={isTablet ? 50 : 60}
            thickness={8}
          >
            <DuckIcon width={isTablet ? 12 : 16} />
          </ProgressCircle>
        )}

        <div>
          <Title>
            {nrOfProgress === 0
              ? "It's time to create your profile"
              : nrOfProgress === 1
              ? "You're on track! Take another test"
              : nrOfProgress === 2
              ? "Almost there! Complete your profile"
              : "Continue building your profile"}
          </Title>
          <Text>
            {nrOfProgress === 0
              ? "Take 3 skills tests to complete your Skills Profile and get discovered by companies"
              : nrOfProgress === 1
              ? "Take 2 more tests to complete your Skills Profile"
              : nrOfProgress === 2
              ? "You're just one skill test away from completing your profile"
              : "You're doing great, why stop now? Add more skills to your profile"}
          </Text>
        </div>
      </ContentWrapper>

      <ActionWrapper>
        <AddSkillButton
          variant={loading || nrOfProgress > 2 ? "secondary" : "primary"}
          Icon={
            <Icon
              icon={faPlus}
              color={loading || nrOfProgress > 2 ? "purple-100" : "white"}
            />
          }
          onClick={() => setOpenSkillAssessmentModal(true)}
        >
          Add skill
        </AddSkillButton>
        <SkillAssessmentAddModal
          open={openSkillAssessmentModal}
          onClose={() => setOpenSkillAssessmentModal(false)}
        />
      </ActionWrapper>
    </SkillProfileProgressContainer>
  );
}

const SkillProfileProgressContainer = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
  background-color: ${(props) => props.theme.colors.white};

  @media ${device.tablet} {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 16px;
`;

const ImageWrapper = styled.div`
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: none;

  @media ${device.tablet} {
    width: 50px;
    height: 50px;
  }
`;

const Title = styled.div`
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 2px;
`;

const Text = styled.div`
  font-size: 14px;
  color: ${(props) => props.theme.typography.colorSecondary};

  @media ${device.tablet} {
    font-size: 12px;
  }
`;

const ActionWrapper = styled.div`
  margin-left: auto;

  @media ${device.tablet} {
    width: 100%;
  }
`;

const AddSkillButton = styled(DSButton)`
  width: 100%;
`;
