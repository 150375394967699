import { useEffect, useRef } from "react";
import type { AppProps } from "next/app";
import * as Sentry from "@sentry/react";
import { config } from "@fortawesome/fontawesome-svg-core";
import ReactModal from "react-modal";
import "@fortawesome/fontawesome-svg-core/styles.css";
import { withUrqlClient } from "next-urql";
import { dedupExchange } from "@urql/core";
import { multipartFetchExchange } from "@urql/exchange-multipart-fetch";
import { devtoolsExchange } from "@urql/devtools";
import { TogglThemeProvider } from "@hundred5/design-system";
import {
  FlashMessagesProvider,
  Tooltip,
  useCssVariableViewHeight,
} from "@/features/common";
import { GlobalStyles } from "@/styles/global-styles";
import { AuthProvider } from "@/features/auth";
import {
  graphcacheExchange,
  urqlAuthExchange,
  urqlSentryExchange,
} from "@/utils/urql";
import { ErrorHandler } from "@/features/error";
import { AmplitudeProvider, Smartlook } from "@/features/analytics";
import "@/styles/fonts.css";

config.autoAddCss = false;

function App({ Component, pageProps }: AppProps) {
  useCssVariableViewHeight();
  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (ref.current != null) {
      ReactModal.setAppElement(ref.current);
    }
  }, []);

  return (
    <Sentry.ErrorBoundary fallback={<ErrorHandler />}>
      <AuthProvider>
        <AmplitudeProvider>
          <TogglThemeProvider>
            <GlobalStyles />
            <FlashMessagesProvider>
              <div id="app" ref={ref}>
                <Component {...pageProps} />
              </div>
            </FlashMessagesProvider>
            <Tooltip />
          </TogglThemeProvider>
        </AmplitudeProvider>
        <Smartlook />
      </AuthProvider>
    </Sentry.ErrorBoundary>
  );
}

export default withUrqlClient(
  (ssrExchange) => ({
    url: `${process.env.NEXT_PUBLIC_API_URL}/candidate_portal/graph`,
    exchanges: [
      devtoolsExchange,
      dedupExchange,
      graphcacheExchange,
      ssrExchange,
      urqlAuthExchange,
      urqlSentryExchange,
      multipartFetchExchange,
    ],
  }),
  { ssr: true }
)(App);
