import React, { useMemo } from "react";
import { useField } from "formik";
import { uniqueId } from "lodash";
import { DSField, DSComboboxMultiple } from "@hundred5/design-system";

type TItem = {
  id: string;
  label: string;
};

interface FormikComboboxMultipleProps {
  name: string;
  items: TItem[];
  label?: React.ReactNode;
  required?: boolean;
  id?: string;
  maxSelectedItemCount?: number;
  placeholder?: string;
  disabled?: boolean;
  maxDropdownHeight?: string;
  errorAbsolute?: boolean;
  onChange?: (selectedItemIds?: string[] | undefined) => void;
  onCreate?: (value: string) => void;
}

export function FormikComboboxMultiple(props: FormikComboboxMultipleProps) {
  const [field, meta, helpers] = useField<any>(props.name);
  const error = meta.touched && !!meta.error ? meta.error : null;
  const id = useMemo(() => props.id || uniqueId("formik-field-"), [props.id]);

  return (
    <DSField
      for={id}
      label={props.label}
      required={props.required}
      error={error}
      errorAbsolute={props.errorAbsolute}
    >
      <DSComboboxMultiple
        id={id}
        {...field}
        items={props.items}
        selectedItemIds={field.value}
        placeholder={props.placeholder}
        maxSelectedItemCount={props.maxSelectedItemCount}
        maxDropdownHeight={props.maxDropdownHeight}
        disabled={props.disabled}
        error={!!error}
        onChange={(selectedItemIds) => {
          if (props.onChange) {
            props.onChange(selectedItemIds);
          } else {
            helpers.setValue(selectedItemIds);
          }
        }}
        onCreate={props.onCreate}
      />
    </DSField>
  );
}
