import React from "react";
import styled from "@emotion/styled";
import { device } from "@/styles/breakpoints";
import { faBars } from "@fortawesome/pro-regular-svg-icons";
import { useLayoutHeaderQuery } from "@/generated/graphql";
import { Icon, NotificationBadge, TogglHireLogo } from "@/features/common";
import { useLayout } from "@/features/layout";

/**
 * Important -> This component is visible only on screen widths less than 768px!
 */

export function LayoutHeader() {
  const { setSidebarCollapsed } = useLayout();
  const [{ data }] = useLayoutHeaderQuery();
  const incompleteProfile =
    !!data?.me && (!data.me.email || !data.me.firstName || !data.me.lastName);

  return (
    <Header>
      <MenuButton onClick={() => setSidebarCollapsed(false)}>
        <Icon icon={faBars} color="peach-70" fontSize="12px" />
        <NotificationBadge
          count={incompleteProfile ? 1 : 0}
          position={{ top: "-4px", right: "-4px" }}
        />
      </MenuButton>
      <Logo>
        <TogglHireLogo type="logo" />
      </Logo>
      <Title>Candidate portal</Title>
    </Header>
  );
}

const Header = styled.header`
  grid-area: header;
  background: ${(props) => props.theme.colors.purple[100]};
  padding: 20px 16px;
  display: none;

  @media ${device.tablet} {
    display: grid;
    grid-template-areas: "menu logo" "menu title";
    grid-auto-columns: min-content auto;
    grid-auto-rows: 20px 14px;
    column-gap: 16px;
    row-gap: 4px;
  }
`;

const MenuButton = styled.button`
  grid-area: menu;
  position: relative;
  align-self: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  -webkit-appearance: none;
  text-align: center;
  cursor: pointer;
  outline: none;
  border: none;
  background: ${(props) => props.theme.colors.purple[90]};
  transition: transform 0.1s;

  &:active {
    transform: scale(0.96);
  }
`;

const Logo = styled.span`
  grid-area: logo;
`;

const Title = styled.div`
  gid-area: title;
  font-weight: 500;
  font-size: 10px;
  text-transform: uppercase;
  color: ${(props) => props.theme.colors.peach[70]};
`;
