import React, { useMemo } from "react";
import { DSCheckbox, DSInlineField } from "@hundred5/design-system";
import { useField } from "formik";
import { uniqueId } from "lodash";

interface FormikCheckboxProps {
  name: string;
  label: React.ReactNode;
  id?: string;
  value?: string;
  checked?: boolean;
  disabled?: boolean;
  errorAbsolute?: boolean;
  hideErrorMsg?: boolean;
  onChange?: (event: React.ChangeEvent<any>) => void;
}

export function FormikCheckbox(props: FormikCheckboxProps) {
  const [field, meta] = useField<any>({
    name: props.name,
    type: "checkbox",
    value: props.value,
  });
  const error = meta.touched && !!meta.error ? meta.error : null;
  const id = useMemo(() => props.id || uniqueId("formik-field-"), [props.id]);

  return (
    <DSInlineField
      for={id}
      label={props.label}
      error={props.hideErrorMsg ? undefined : error}
      errorAbsolute={props.errorAbsolute}
      disabled={props.disabled}
    >
      <DSCheckbox
        id={id}
        error={!!error}
        {...field}
        onChange={(event) => {
          if (props.onChange) props.onChange(event);
          else field.onChange(event);
        }}
        checked={props.checked ?? field.checked}
        disabled={props.disabled}
      />
    </DSInlineField>
  );
}
