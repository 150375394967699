import React, { useCallback, useState } from "react";
import styled from "@emotion/styled";
import { device } from "@/styles/breakpoints";
import { Tooltip } from "@/features/common";
import { useLayout } from "@/features/layout";
import { LayoutSidebarUserMenu } from "../layout-sidebar-user-menu";
import { LayoutSidebarCompleteProfile } from "../layout-sidebar-complete-profile";
import { LayoutSidebarApplicationList } from "../layout-sidebar-application-list";
import { LayoutSidebarFeedback } from "../layout-sidebar-feedback";
import { LayoutSidebarDashboardButton } from "../layout-sidebar-dashboard-button";
import {
  CloseSidebarButton,
  SupportIconButton,
  TogglHireLogoButton,
  ToggleSidebarButton,
  UserIconButton,
} from "./ui";
import { useLayoutSidebarQuery } from "@/generated/graphql";
import { JobOfferChecklist } from "@/features/job-offer";

export function LayoutSidebar() {
  const { sidebarCollapsed, setSidebarCollapsed } = useLayout();
  const [hovering, setHovering] = useState(false);
  const [{ data }] = useLayoutSidebarQuery();

  const handleCollapseToggle = () => {
    setSidebarCollapsed(!sidebarCollapsed);
    if (sidebarCollapsed) setHovering(false);
  };

  const handleMouseEnter = useCallback(() => {
    if (sidebarCollapsed) setHovering(true);
  }, [sidebarCollapsed]);

  const handleMouseLeave = useCallback(() => {
    if (hovering) setHovering(false);
  }, [hovering]);

  return (
    <Sidebar
      collapsed={sidebarCollapsed}
      onMouseLeave={handleMouseLeave}
      onMouseEnter={handleMouseEnter}
    >
      <Tooltip attribute="data-sidebar-tooltip" />

      <Static>
        <TogglHireLogoButton />
        <CloseSidebarButton onClick={() => setSidebarCollapsed(true)} />
        <ToggleSidebarButton
          active={!sidebarCollapsed}
          onClick={handleCollapseToggle}
        />
        <UserIconButton slug={data?.me.slug} />
        <SupportIconButton />
      </Static>

      <Drawer collapsed={sidebarCollapsed} hovering={hovering}>
        <DrawerContent>
          <LayoutSidebarUserMenu />
          <LayoutSidebarCompleteProfile />
          <JobOfferChecklist />
          <LayoutSidebarDashboardButton />
          <LayoutSidebarApplicationList type="active" />
          <LayoutSidebarApplicationList type="past" />
          <LayoutSidebarFeedback />
        </DrawerContent>
      </Drawer>
    </Sidebar>
  );
}

const Sidebar = styled.aside<{ collapsed: boolean }>`
  grid-area: sidebar;
  display: flex;
  flex-direction: row;
  height: 100%;
  width: min-content;
  position: relative;
  z-index: 999;

  @media ${device.tablet} {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: ${(props) => (props.collapsed ? "0" : "100vw")};
    overflow: hidden;
    transition: width 0.2s;
  }
`;

const Static = styled.nav`
  width: 48px;
  padding: 16px 0;
  background: ${(props) => props.theme.colors.purple[120]};
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;

  @media ${device.tablet} {
    width: 62px;
    padding: 24px 0;
  }
`;

const Drawer = styled.nav<{
  collapsed: boolean;
  hovering: boolean;
}>`
  overflow-x: hidden;
  height: ${(props) => (!props.collapsed ? "auto" : "100%")};
  background: ${(props) => props.theme.colors.purple[100]};
  position: ${(props) => (props.collapsed ? "absolute" : "initial")};
  left: 48px;
  width: ${(props) => (props.collapsed && !props.hovering ? "0" : "256px")};
  transition: width 0.2s;

  @media ${device.tablet} {
    left: 62px;
    width: ${(props) =>
      props.collapsed && !props.hovering ? "0" : "calc(100vw - 62px)"};
  }
`;

const DrawerContent = styled.div`
  width: 256px;
  height: 100%;
  display: flex;
  flex-direction: column;

  @media ${device.tablet} {
    width: calc(100vw - 62px);
  }
`;
