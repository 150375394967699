import React from "react";
import styled from "@emotion/styled";
import { device } from "@/styles/breakpoints";

interface StepHeaderProps {
  title: string;
  description?: string;
}

export function StepHeader({ title, description }: StepHeaderProps) {
  return (
    <Header>
      <Title>{title}</Title>
      <Description>{description}</Description>
    </Header>
  );
}

const Header = styled.header`
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${(props) => props.theme.colors.white};
  z-index: 1;
  padding: 56px 36px 16px 36px;
  min-height: 128px;
  gap: 8px;

  @media ${device.tablet} {
    padding: 56px 16px 16px 16px;
    min-height: 148px;
  }
`;

const Title = styled.h1`
  margin: 0;
  font-size: 16px;
  font-weight: 700;
  color: ${(props) => props.theme.typography.colorPrimary};
  text-align: center;
`;

const Description = styled.p`
  margin: 0;
  font-size: 14px;
  font-weight: 400;
  color: ${(props) => props.theme.typography.colorPrimary};
  text-align: center;

  &:empty {
    display: none;
  }
`;
