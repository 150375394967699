import React, { useEffect } from "react";
import styled from "@emotion/styled";
import { useAmplitude } from "@/features/analytics";
import {
  useJobOfferWizardQuery,
  useJobOfferWizardRefreshQuery,
  useJobOfferWizardSubmitMutation,
} from "@/generated/graphql";
import { useApiErrorHandler } from "@/features/error";
import { Loader, WizardProvider } from "@/features/common";
import { IJobOfferWizard } from "@/features/job-offer";
import {
  WizardStepComplete,
  WizardStepContactDetails,
  WizardStepLocation,
  WizardProgress,
  WizardStepLanguage,
  WizardStepSeniority,
  WizardStepSalary,
  WizardStepRole,
} from "./ui";

interface JobOfferWizardProps {
  isEditing?: boolean;
  onComplete?: () => void;
}

export function JobOfferWizard({ isEditing, onComplete }: JobOfferWizardProps) {
  const handleError = useApiErrorHandler();
  const { logEvent } = useAmplitude();

  const [{ data, error, fetching }] = useJobOfferWizardQuery();
  const [{}, reexecuteQuery] = useJobOfferWizardRefreshQuery({
    variables: { slug: data?.me.slug! },
    pause: true,
  });
  const [, jobOfferWizardSubmit] = useJobOfferWizardSubmitMutation();

  useEffect(() => {
    if (!error) return;
    handleError(error);
  }, [handleError, error]);

  const initialValues: IJobOfferWizard = {
    countryCodes: data?.jobOfferSettings?.countryCodes ?? [],
    remote: data?.jobOfferSettings?.remote ?? false,
    timezoneName: data?.jobOfferSettings?.timezoneName ?? null,
    timezoneOffset: data?.jobOfferSettings?.timezoneOffset ?? null,
    languageCodes: data?.jobOfferSettings?.languageCodes ?? [],
    positionIDs: data?.jobOfferSettings?.positionIDs ?? [],
    seniorityLevels: data?.jobOfferSettings?.seniorityLevels ?? [],
    salary: data?.jobOfferSettings?.salary ?? null,
    salaryCurrency: data?.jobOfferSettings?.salaryCurrency ?? null,
    firstName: data?.me.firstName ?? "",
    lastName: data?.me.lastName ?? "",
    contactEmail: (data?.me.contactEmail || data?.me.email) ?? "",
    linkedin: data?.me.linkedin ?? "",
  };

  const handleSubmit = async (values: IJobOfferWizard) => {
    const result = await jobOfferWizardSubmit({
      jobOfferSettings: {
        countryCodes: values.countryCodes,
        remote: values.remote,
        timezoneName: values.timezoneName,
        timezoneOffset: values.timezoneOffset,
        languageCodes: values.languageCodes,
        positionIDs: values.positionIDs,
        seniorityLevels: values.seniorityLevels,
        salary: values.salary!,
        salaryCurrency: values.salaryCurrency!,
      },
      firstName: values.firstName,
      lastName: values.lastName,
      contactEmail: values.contactEmail,
      linkedin: values.linkedin,
    });

    if (result.error) {
      handleError(result.error);
      return false;
    } else {
      logEvent("accepts job offers/enabled");
      // refresh
      reexecuteQuery({ requestPolicy: "network-only" });
    }
    return true;
  };

  const handleComplete = () => {
    onComplete?.();
  };

  if (fetching) {
    return <Loader />;
  }

  return (
    <WizardProvider<IJobOfferWizard>
      stepCount={6}
      initalData={initialValues}
      onSubmit={handleSubmit}
      onComplete={handleComplete}
    >
      <WizardProgress />

      <JobOfferWizardContainer>
        <WizardProvider.Step index={0}>
          <WizardStepLocation />
        </WizardProvider.Step>

        <WizardProvider.Step index={1}>
          <WizardStepLanguage />
        </WizardProvider.Step>

        <WizardProvider.Step index={2}>
          <WizardStepRole />
        </WizardProvider.Step>

        <WizardProvider.Step index={3}>
          <WizardStepSeniority />
        </WizardProvider.Step>

        <WizardProvider.Step index={4}>
          <WizardStepSalary />
        </WizardProvider.Step>

        <WizardProvider.Step index={5}>
          <WizardStepContactDetails />
        </WizardProvider.Step>

        <WizardProvider.Step index={6}>
          <WizardStepComplete isEditing={isEditing} />
        </WizardProvider.Step>
      </JobOfferWizardContainer>
    </WizardProvider>
  );
}

const JobOfferWizardContainer = styled.div`
  position: relative;
`;
