import React, { useCallback } from "react";
import { DSNotification } from "@hundred5/design-system";
import { useFlashMessages } from "../flash-messages.utils";
import { IFlashMessage } from "../flash-messages.types";

interface MessageProps {
  message: IFlashMessage;
}

export const Message = ({ message }: MessageProps) => {
  const { id, content } = message;
  const { removeMessage } = useFlashMessages();
  const close = useCallback(() => removeMessage(id), [id, removeMessage]);

  switch (content.type) {
    case "network_error":
      return (
        <DSNotification
          type="failure"
          title="Network error"
          content="There’s a network disconnect. Try checking your network connection."
          onClose={close}
        />
      );

    case "api_error":
      const supportEmail = process.env.NEXT_PUBLIC_SUPPORT_EMAIL;

      return (
        <DSNotification
          type="failure"
          title="Unexpected error"
          content={
            <>
              We can’t determine the cause of error.
              <br />
              Please contact us at{" "}
              <a href={`mailto:${supportEmail}`}>{supportEmail}</a> providing
              the following information:
              <br />
              <code>
                {content.requestId}
                <br />
                {content.error}
              </code>
            </>
          }
          onClose={close}
        />
      );

    case "save_success":
      return (
        <DSNotification
          type="success"
          title="Changes saved!"
          autoClose={true}
          onClose={close}
        />
      );

    case "upload_file_size_error":
      return (
        <DSNotification
          type="failure"
          title="File too large"
          content={`The file size is ${
            !content.maxSizeMB
              ? "too large"
              : `limited to ${content.maxSizeMB} MB`
          }, please check the size of the file you are trying to upload and try again.`}
          onClose={close}
        />
      );

    case "validation_error":
      return (
        <DSNotification
          type="failure"
          title="Validation failed"
          content={`Error: ${content.error}`}
          onClose={close}
        />
      );

    case "unauthorized":
      return (
        <DSNotification
          type="failure"
          title="Unauthorized"
          content={`Error: ${content.error}`}
          onClose={close}
        />
      );

    case "link_copied":
      return (
        <DSNotification
          type="success"
          title="Link copied to clipboard"
          autoClose={true}
          onClose={close}
        />
      );

    case "picture_upload_error":
      return (
        <DSNotification
          type="failure"
          title="Failed to upload profile picture"
          content={content.error}
          onClose={close}
        />
      );

    case "job_offer_disabled_success":
      return (
        <DSNotification
          type="success"
          title="Job Offers disabled"
          content="You won’t receive any Job Offers for now."
          actionLabel={content.actionLabel}
          onActionClick={() => {
            content.onActionClick?.();
            close(); // todo: this could be moved to DS
          }}
          autoClose={true}
          onClose={close}
        />
      );

    default:
      return null;
  }
};
