import React, { useEffect, useMemo } from "react";
import { useLayoutSidebarUserMenuQuery } from "@/generated/graphql";
import { useApiErrorHandler } from "@/features/error";
import { useLogout } from "@/features/auth";
import { TMenuItem } from "./layout-sidebar-user-menu.types";
import { LayoutSidebarUserMenuView } from "./layout-sidebar-user-menu.view";

export function LayoutSidebarUserMenu() {
  const [{ data, error }] = useLayoutSidebarUserMenuQuery();
  const handleError = useApiErrorHandler();
  const logOut = useLogout();

  const menuItems: TMenuItem[] = useMemo(
    () => [
      {
        label: "Sign out",
        onClick: logOut,
        dangerous: true,
      },
    ],
    [logOut]
  );

  useEffect(() => {
    if (!error) return;
    handleError(error);
  }, [handleError, error]);

  return (
    <LayoutSidebarUserMenuView
      userFirstName={data?.me.firstName}
      userLastName={data?.me.lastName}
      menuItems={menuItems}
    />
  );
}
