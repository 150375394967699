import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";

interface TabsProps extends React.HTMLAttributes<HTMLDivElement> {
  tabs?: string[];
  activeTab: string;
  onTabClick: (tab: string) => void;
}

export const Tabs = React.forwardRef<HTMLDivElement, TabsProps>(
  ({ tabs, activeTab, onTabClick, ...rest }: TabsProps, ref) => {
    return (
      <TabsContainer {...rest} ref={ref}>
        {tabs?.map((tab) => (
          <TabsItem
            key={tab}
            active={tab === activeTab}
            onClick={() => onTabClick(tab)}
          >
            {tab}
          </TabsItem>
        ))}
      </TabsContainer>
    );
  }
);

const TabsContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 10px;
  margin: 0 -10px;
  height: 30px;
`;

const TabsItem = styled.div<{ active: boolean }>`
  font-weight: 400;
  font-size: 12px;
  color: ${(props) => props.theme.typography.colorPrimary};
  white-space: nowrap;
  height: 24px;
  padding: 0 10px;
  display: flex;
  align-items: center;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.1s;

  &:hover {
    background-color: ${(props) => !props.active && props.theme.hover.onWhite};
  }

  ${(props) =>
    props.active &&
    css`
      font-weight: 700;
      position: relative;

      ::after {
        position: absolute;
        content: " ";
        width: calc(100% - 20px);
        left: 10px;
        bottom: -5px;
        border-bottom: 1px solid ${props.theme.colors.orange[100]};
      }
    `}
`;
