import { useCallback, useEffect } from "react";

export function useCssVariableViewHeight() {
  const calculateHeight = useCallback(() => {
    var vh = window?.innerHeight || 100;
    document.documentElement.style.setProperty("--view-height", vh + "px");
  }, []);

  useEffect(() => {
    calculateHeight();

    window?.addEventListener("resize", calculateHeight);
    window?.addEventListener("orientationchange", calculateHeight);

    return () => {
      window?.removeEventListener("resize", calculateHeight);
      window?.removeEventListener("orientationchange", calculateHeight);
    };
  }, [calculateHeight]);
}
