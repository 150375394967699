import React, { useMemo } from "react";
import { useField } from "formik";
import { uniqueId } from "lodash";
import { DSField, DSInput } from "@hundred5/design-system";

interface FormikInputProps {
  name: string;
  label: React.ReactNode;
  required?: boolean;
  id?: string;
  type?: string;
  placeholder?: string;
  readonly?: boolean;
  disabled?: boolean;
  errorAbsolute?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export function FormikInput(props: FormikInputProps) {
  const [field, meta] = useField<any>(props.name);
  const error = meta.touched && !!meta.error ? meta.error : null;
  const id = useMemo(() => props.id || uniqueId("formik-field-"), [props.id]);

  return (
    <DSField
      for={id}
      label={props.label}
      required={props.required}
      error={error}
      errorAbsolute={props.errorAbsolute}
    >
      <DSInput
        id={id}
        type={props.type}
        placeholder={props.placeholder}
        error={!!error}
        {...field}
        readOnly={props.readonly}
        disabled={props.disabled}
        onChange={(event) => {
          if (props.onChange) {
            props.onChange(event);
          } else {
            field.onChange(event);
          }
        }}
      />
    </DSField>
  );
}
