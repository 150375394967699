import React from "react";
import styled from "@emotion/styled";
import { faPlus } from "@fortawesome/pro-regular-svg-icons";
import { Icon } from "@/features/common";

export interface SelectableItemProps {
  id: string;
  label: string;
  selected: boolean;
  onClick: (id: string) => void;
  disabled?: boolean;
}

export function SelectableItem({
  id,
  label,
  selected,
  onClick,
  disabled,
}: SelectableItemProps) {
  return (
    <SelectableItemButton
      type="button"
      onClick={() => onClick(id)}
      selected={selected}
      disabled={disabled}
    >
      <div>{label}</div>
      <div>
        <Icon icon={faPlus} />
      </div>
    </SelectableItemButton>
  );
}

const SelectableItemButton = styled.button<{
  selected: boolean;
}>`
  background: ${({ theme, selected }) =>
    selected ? theme.colors.purple[100] : "transparent"};
  border-color: ${({ theme, selected }) =>
    selected ? theme.colors.purple[100] : theme.colors.purple[40]};
  border-radius: 8px;
  border-style: solid;
  border-width: 1px;
  font-size: 12px;
  font-weight: 400;
  color: ${({ theme, selected }) =>
    selected ? theme.colors.white : theme.colors.purple[100]};
  cursor: pointer;
  display: flex;
  padding: 0;
  height: 28px;
  line-height: 100%;
  &:disabled {
    pointer-events: none;
    opacity: 0.4;
  }
  &:hover {
    background: ${({ theme, selected }) =>
      selected ? theme.colors.purple[90] : theme.colors.purple[5]};
  }
  div {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 8px;
  }
  div:first-of-type {
    border-right-width: 1px;
    border-right-style: solid;
    border-right-color: ${({ theme, selected }) =>
      selected ? theme.colors.white : theme.colors.purple[40]};
  }
  svg {
    transform: rotate(${({ selected }) => (selected ? "45deg" : "0deg")});
    transition: transform 100ms ease-in-out;
  }
  svg path {
    fill: ${({ theme, selected }) =>
      selected ? theme.colors.white : theme.colors.purple[60]};
  }
`;
