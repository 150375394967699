import React from "react";
import styled from "@emotion/styled";
import { useLoggedIn } from "@/features/auth";
import { Loader } from "@/features/common";
import { LoginPage } from "@/features/auth";
import { Layout } from "@/features/layout";

//region Main
/**
 * A page that is locked behind authentication. If the user is not logged in, they will be shown a login form.
 */
export function LockedPage(props: React.PropsWithChildren) {
  const loggedIn = useLoggedIn();

  if (loggedIn === true) {
    return <Layout>{props.children}</Layout>;
  } else if (loggedIn === false) {
    return <LoginPage />;
  } else {
    return (
      <Centered>
        <Loader />
      </Centered>
    );
  }
}
//endregion

//region Styles

const Centered = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: var(--view-height);
  padding: 20px;
`;
//endregion
