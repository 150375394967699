import React from "react";
import styled from "@emotion/styled";
import { device } from "@/styles/breakpoints";
import { Icon } from "@/features/common";
import { faXmark } from "@fortawesome/pro-regular-svg-icons";

interface CloseSidebarButtonProps {
  onClick: () => void;
}

export function CloseSidebarButton({ onClick }: CloseSidebarButtonProps) {
  return (
    <CloseButton onClick={onClick}>
      <Icon icon={faXmark} color="peach-70" fontSize="12px" />
    </CloseButton>
  );
}

const CloseButton = styled.button`
  grid-area: menu;
  display: none;
  align-self: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  -webkit-appearance: none;
  text-align: center;
  cursor: pointer;
  outline: none;
  border: none;
  background: ${(props) => props.theme.colors.purple[90]};
  margin-bottom: 24px;

  @media ${device.tablet} {
    display: inline-flex;
  }
`;
