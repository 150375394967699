import React, { useCallback, useEffect } from "react";
import styled from "@emotion/styled";
import { Form, Formik } from "formik";
import { array, object, string } from "yup";
import { faArrowLeft, faArrowRight } from "@fortawesome/pro-regular-svg-icons";
import { DSButton, DSField } from "@hundred5/design-system";
import {
  usePositionCategoriesQuery,
  usePositionsQuery,
} from "@/generated/graphql";
import { useApiErrorHandler } from "@/features/error";
import {
  FormikComboboxMultiple,
  Icon,
  Loader,
  useWizard,
} from "@/features/common";
import { IJobOfferWizard } from "@/features/job-offer";
import { StepHeader, StepContent, StepFooter, SelectableItem } from ".";

const validationSchema = object().shape({
  categoryID: string().required("This field is mandatory"),
  positionIDs: array()
    .of(string())
    .min(1, "Select at least one option")
    .max(3, "Select at most three options"),
});

export function WizardStepRole() {
  const handleError = useApiErrorHandler();
  const { data, setData, goBack, goForward } = useWizard<IJobOfferWizard>();
  const [
    {
      data: categoriesData,
      fetching: fetchingCategories,
      error: categoriesError,
    },
  ] = usePositionCategoriesQuery();
  const [
    { data: positionsData, fetching: fetchingPositions, error: positionsError },
  ] = usePositionsQuery();

  useEffect(() => {
    if (!categoriesError) return;
    handleError(categoriesError);
  }, [handleError, categoriesError]);

  useEffect(() => {
    if (!positionsError) return;
    handleError(positionsError);
  }, [handleError, positionsError]);

  const getCategories = useCallback(
    (categoryId: string | null) => {
      if (!categoriesData?.positionCategories.length) return [];

      return categoriesData.positionCategories.filter((category) =>
        !!categoryId ? categoryId === category.id : category
      );
    },
    [categoriesData?.positionCategories]
  );

  const getPositionsByCategory = useCallback(
    (categoryId: string) => {
      if (!positionsData?.positions.length) return [];

      return positionsData.positions.filter(
        (position) => position.categoryId === categoryId
      );
    },
    [positionsData?.positions]
  );

  const handleForward = (values: any) => {
    setData({ ...data, ...values });
    goForward();
  };

  return (
    <Formik
      onSubmit={handleForward}
      initialValues={{
        categoryID:
          positionsData?.positions.find(
            (position) => position.id === data.positionIDs[0]
          )?.categoryId ?? null,
        positionIDs: data.positionIDs,
      }}
      validationSchema={validationSchema}
      enableReinitialize={true}
      validateOnMount={true}
    >
      {({ values, touched, errors, setFieldValue, isValid, isSubmitting }) => (
        <Form data-recording-ignore="mask">
          <StepHeader title="What type of opportunities do you want to see?" />

          <RoleStepContent>
            {fetchingCategories || fetchingPositions ? (
              <Loader />
            ) : (
              <>
                <CategoryField
                  for="categoryID"
                  error={touched.categoryID && errors.categoryID}
                >
                  {getCategories(values.categoryID).map((category) => (
                    <SelectableItem
                      key={category.id}
                      id={category.id}
                      label={category.name}
                      selected={values.categoryID === category.id}
                      onClick={(id) => {
                        if (values.categoryID === category.id) {
                          setFieldValue("categoryID", null);
                          setFieldValue("positionIDs", []);
                        } else {
                          setFieldValue("categoryID", id);
                        }
                      }}
                    />
                  ))}
                </CategoryField>

                {!!values.categoryID && (
                  <>
                    <Subtitle>
                      Select the most relevant roles for you (max 3)
                    </Subtitle>
                    <FormikComboboxMultiple
                      label="Roles"
                      name="positionIDs"
                      placeholder="Type to search..."
                      items={getPositionsByCategory(values.categoryID).map(
                        (position) => ({
                          id: position.id,
                          label: position.name,
                        })
                      )}
                      required
                      maxSelectedItemCount={3}
                      maxDropdownHeight="152px"
                    />
                  </>
                )}
              </>
            )}
          </RoleStepContent>

          <StepFooter>
            <DSButton variant="secondary" onClick={goBack}>
              <Icon icon={faArrowLeft} />
            </DSButton>
            <DSButton type="submit" disabled={!isValid || isSubmitting}>
              Next <Icon icon={faArrowRight} color="white" />
            </DSButton>
          </StepFooter>
        </Form>
      )}
    </Formik>
  );
}

const RoleStepContent = styled(StepContent)`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const CategoryField = styled(DSField)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 8px;
`;

const Subtitle = styled.h2`
  margin: 8px 0 0;
  font-size: 14px;
  font-weight: 400;
  color: ${(props) => props.theme.typography.colorPrimary};
`;
