import React from "react";
import styled from "@emotion/styled";
import Image from "next/image";
import { DSButton } from "@hundred5/design-system";
import { useWizard } from "@/features/common";

import illustration from "./illustration.svg";
import { StepContent, StepFooter, StepHeader } from ".";

interface WizardStepCompleteProps {
  isEditing?: boolean;
}

export function WizardStepComplete({ isEditing }: WizardStepCompleteProps) {
  const { onComplete } = useWizard();
  return (
    <>
      <StepHeader
        title={
          isEditing
            ? "Your preferences have been updated!"
            : "You have enabled personalized Job Offers!"
        }
      />
      <StepContent>
        <Illustration>
          <Image src={illustration} alt="illustration" />
        </Illustration>
        <Description>
          We’ll match your top skills to new job openings via Toggl Hire. All
          new job matches will appear on your dashboard, so stay tuned!
          {!isEditing && (
            <>
              <br />
              <br />
              To edit your preferences, go to ‘My Profile’.
            </>
          )}
        </Description>
      </StepContent>
      <StepFooter>
        <DSButton onClick={onComplete}>Done</DSButton>
      </StepFooter>
    </>
  );
}

const Illustration = styled.div`
  text-align: center;
  margin-top: -6px;
  margin-bottom: 30px;
  height: 90px;
`;

const Description = styled.div`
  text-align: center;
`;
