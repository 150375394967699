import React from "react";
import { faEye, faEyeSlash } from "@fortawesome/pro-regular-svg-icons";
import { DSButton } from "@hundred5/design-system";
import { Icon } from "../icon";

interface VisibilityButtonProps {
  value: boolean;
  onClick: (newValue: boolean) => void;
  disabled?: boolean;
  disabledTooltip?: string;
}

export function VisibilityButton({
  value,
  onClick,
  disabled,
  disabledTooltip,
}: VisibilityButtonProps) {
  return (
    <DSButton
      variant="secondary"
      Icon={
        <Icon
          icon={value ? faEye : faEyeSlash}
          color="purple-100"
          fontSize="12px"
        />
      }
      disabled={disabled}
      data-rh-at="right"
      data-rh={disabled ? disabledTooltip : null}
      onClick={() => onClick(!value)}
    />
  );
}
