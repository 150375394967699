import { createContext, useContext, useEffect, useState } from "react";
import { useMedia } from "react-use";
import { store } from "@/features/common";
import { device } from "@/styles/breakpoints";
import { userHasConsentedToPreferences } from "@/utils/cookie-permissions";

const STORE_KEY = "sidebar-collapsed";

type TLayoutContext = {
  sidebarCollapsed: boolean;
  setSidebarCollapsed: (value: boolean) => void;
};

const LayoutContext = createContext<TLayoutContext>({
  sidebarCollapsed: false,
  setSidebarCollapsed: (_value: boolean) => {},
});

export function LayoutProvider({ children }: React.PropsWithChildren) {
  const isTablet = useMedia(device.tablet);

  const [sidebarCollapsed, setSidebarCollapsed] = useState(
    isTablet || store.get(STORE_KEY, false)
  );

  useEffect(() => {
    if (userHasConsentedToPreferences()) {
      store.set(STORE_KEY, sidebarCollapsed);
    }
  }, [sidebarCollapsed]);

  return (
    <LayoutContext.Provider value={{ sidebarCollapsed, setSidebarCollapsed }}>
      {children}
    </LayoutContext.Provider>
  );
}

export function useLayout() {
  return useContext(LayoutContext);
}
