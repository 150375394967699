import React, { useCallback, useEffect, useState } from "react";
import {
  JobOfferSettings,
  useDisableJobOffersMutation,
  useEnableJobOffersMutation,
  useJobOfferPreferencesQuery,
} from "@/generated/graphql";
import {
  JobOfferWizardModal,
  useJobOfferEnableProgress,
} from "@/features/job-offer";
import { useAmplitude } from "@/features/analytics";
import { useApiErrorHandler } from "@/features/error";
import { useFlashMessages } from "@/features/common";
import { Disabled, Enabled, Preferences } from "./ui";

export function JobOfferPreferences() {
  const handleError = useApiErrorHandler();
  const { addMessage } = useFlashMessages();
  const { logEvent } = useAmplitude();

  const [wizardModalProps, setWizardModalProps] = useState<{
    isOpen: boolean;
    isEditing?: boolean;
  }>({ isOpen: false });

  const { loading, assessedSkillCount, jobOffersEnabled } =
    useJobOfferEnableProgress();

  const [{ data, error }] = useJobOfferPreferencesQuery({
    pause: !jobOffersEnabled,
  });
  const [, disableJobOffers] = useDisableJobOffersMutation();
  const [, enableJobOffers] = useEnableJobOffersMutation();

  useEffect(() => {
    if (!error) return;
    handleError(error);
  }, [handleError, error]);

  const undoDisableJobOffers = useCallback(
    async (settings: JobOfferSettings) => {
      const result = await enableJobOffers({
        jobOfferSettings: {
          countryCodes: settings.countryCodes,
          remote: settings.remote,
          timezoneName: settings.timezoneName,
          timezoneOffset: settings.timezoneOffset,
          languageCodes: settings.languageCodes,
          positionIDs: settings.positionIDs,
          seniorityLevels: settings.seniorityLevels,
          salary: settings.salary!,
          salaryCurrency: settings.salaryCurrency!,
        },
      });

      if (result.error) {
        handleError(result.error);
      } else {
        addMessage({ type: "save_success" });
        logEvent("accepts job offers/enabled");
      }
    },
    [addMessage, enableJobOffers, handleError, logEvent]
  );

  const handleDisableJobOffers = useCallback(async () => {
    const result = await disableJobOffers({});

    if (result.error) {
      handleError(result.error);
    } else {
      /*
       * Previously no preferences were required, but now they are,
       * so "undo" action will cause an error in case required preferences are missing
       */
      const canUndo =
        !!data?.jobOfferSettings?.countryCodes.length &&
        (data?.jobOfferSettings?.remote === false ||
          (data?.jobOfferSettings?.remote === true &&
            !!data?.jobOfferSettings?.timezoneName)) &&
        !!data?.jobOfferSettings?.positionIDs.length &&
        !!data?.jobOfferSettings?.seniorityLevels.length &&
        !!data?.jobOfferSettings?.salary &&
        !!data?.jobOfferSettings?.salaryCurrency;

      addMessage({
        type: "job_offer_disabled_success",
        actionLabel: canUndo ? "Undo" : undefined,
        onActionClick: canUndo
          ? () => undoDisableJobOffers(data.jobOfferSettings!)
          : undefined,
      });
      logEvent("accepts job offers/disabled");
    }
  }, [
    addMessage,
    data?.jobOfferSettings,
    disableJobOffers,
    handleError,
    logEvent,
    undoDisableJobOffers,
  ]);

  if (loading && jobOffersEnabled === undefined) {
    return null;
  }

  return (
    <>
      {jobOffersEnabled ? (
        <Enabled
          onEdit={() => setWizardModalProps({ isOpen: true, isEditing: true })}
          onDisable={handleDisableJobOffers}
        >
          <Preferences
            contactEmail={data?.me.contactEmail || data?.me.email}
            jobOfferSettings={data?.jobOfferSettings}
          />
        </Enabled>
      ) : (
        <Disabled
          canEnable={assessedSkillCount > 2}
          onEnable={() => setWizardModalProps({ isOpen: true })}
        />
      )}

      <JobOfferWizardModal
        isOpen={wizardModalProps.isOpen}
        isEditing={wizardModalProps.isEditing}
        onClose={() => setWizardModalProps({ isOpen: false })}
      />
    </>
  );
}
