import { Exchange } from "urql";
import { pipe, tap } from "wonka";
import { getOperationName, makeOperation } from "@urql/core";
import * as Sentry from "@sentry/nextjs";
import { authExchange } from "@urql/exchange-auth";
import { getTokenFromStorage, removeTokenFromStorage } from "@/features/auth";
import { hasGraphQLError } from "@/utils/urql/errors";
import { cacheExchange } from "@urql/exchange-graphcache";
import schema from "@/generated/schema";

export const urqlSentryExchange: Exchange = ({ forward }) => {
  return (ops$) =>
    pipe(
      ops$,
      forward,
      tap((result) => {
        const category = `graphql.${result.operation.kind}`;
        const message = getOperationName(result.operation.query);
        const data: any = result.operation.variables;

        Sentry.addBreadcrumb({ category, message, data });
      })
    );
};

export const urqlAuthExchange = authExchange<{ token: string | null }>({
  getAuth: async ({ authState }) => {
    if (!authState) {
      const token = getTokenFromStorage();

      if (token) {
        return { token };
      }
      return null;
    }

    removeTokenFromStorage();
    window.location.reload();
    return null;
  },

  addAuthToOperation: ({ authState, operation }) => {
    if (!authState || !authState.token) {
      return operation;
    }

    const fetchOptions =
      typeof operation.context.fetchOptions === "function"
        ? operation.context.fetchOptions()
        : operation.context.fetchOptions || {};

    return makeOperation(operation.kind, operation, {
      ...operation.context,
      fetchOptions: {
        ...fetchOptions,
        headers: {
          ...fetchOptions.headers,
          authorization: `Bearer ${authState.token}`,
        },
      },
    });
  },

  willAuthError: (params) => {
    return params.authState?.token !== getTokenFromStorage();
  },

  didAuthError: ({ error }) => {
    return hasGraphQLError(error, "Unauthorized");
  },
});

export const graphcacheExchange = cacheExchange({
  schema,
  keys: {
    Candidate: (data) => String(data.id),
    CandidateSkill: (data) => String(data.id),
    Application: (data) => String(data.slug),
    Test: (data) => String(data.slug),
    Workspace: () => null,
    Skill: () => null,
    Document: (data) => String(data.id),
    CustomField: (data) => String(data.id),
    ContactInfo: (data) => String(data.id),
    Assessment: () => null,
    CandidateSkillAssessment: () => null,
    PublicSkill: (data) => String(data._id),
    PublicProfile: (data) => String(data.id),
    PublicProfileSettings: (data) => String(data.id),
    ProfilePictureURL: () => null,
    JobOfferSettings: (data) => String(data._id),
  },
});
