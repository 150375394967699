import React from "react";
import styled from "@emotion/styled";
import { useAsyncFn } from "react-use";
import { faLinkedin, faSquareGithub } from "@fortawesome/free-brands-svg-icons";
import { DSButton } from "@hundred5/design-system";
import { Icon } from "@/features/common";
import { LoginProviders } from "@/features/auth";
import { TestLogin } from "./test-login";

interface LoginButtonProps {
  method: LoginProviders;
  onLogin: (email?: string) => Promise<void>;
  disabled?: boolean;
}

export function LoginButton(props: LoginButtonProps) {
  const [{ loading }, logIn] = useAsyncFn(props.onLogin);
  const disabled = props.disabled || loading;

  const handleClick = (email?: string) => logIn(email);

  if (props.method === "test") {
    return (
      <TestLogin loading={loading} onLogin={(email) => handleClick(email)} />
    );
  } else if (props.method === "linkedin") {
    return (
      <LinkedInButton disabled={disabled} onClick={() => handleClick()}>
        <Icon icon={faLinkedin as any} fontSize={"20px"} color="white" />
        Log in with LinkedIn
      </LinkedInButton>
    );
  } else if (props.method === "github") {
    return (
      <GithubButton disabled={disabled} onClick={() => handleClick()}>
        <Icon icon={faSquareGithub as any} fontSize={"20px"} color="white" />
        Log in with GitHub
      </GithubButton>
    );
  } else {
    return null;
  }
}

const LinkedInButton = styled(DSButton)`
  background-color: #0077b5;
  color: white;
  width: 100%;

  &:hover {
    background: #006097;
  }
`;

const GithubButton = styled(DSButton)`
  background-color: #1a1a1a;
  color: white;
  width: 100%;

  &:hover {
    background: #333;
  }
`;
