import React from "react";
import styled from "@emotion/styled";
import { device } from "@/styles/breakpoints";

interface ApplicationListItemProps {
  applicationTitle: string;
  workspaceName?: string;
  onClick: () => void;
  active: boolean;
}

export function ApplicationListItem({
  applicationTitle,
  workspaceName,
  onClick,
  active,
}: ApplicationListItemProps) {
  return (
    <ListItem onClick={onClick} active={active} data-recording-sensitive>
      <span>
        {applicationTitle}
        {!!workspaceName && ` - ${workspaceName}`}
      </span>
    </ListItem>
  );
}

const ListItem = styled.li<{ active: boolean }>`
  font-weight: 400;
  font-size: 14px;
  color: ${(props) => props.theme.colors.peach[70]};
  padding: 0 16px;
  height: 28px;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: background 0.1s;
  background: ${(props) =>
    props.active ? props.theme.colors.orange[100] : "none"};

  &:hover {
    background: ${(props) =>
      props.active
        ? props.theme.colors.orange[120]
        : props.theme.colors.purple[90]};
  }

  & > span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  @media ${device.tablet} {
    background: none;
  }
`;
