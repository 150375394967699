import React, { useState } from "react";
import styled from "@emotion/styled";
import { DSButton, DSCheckbox } from "@hundred5/design-system";
import { ProgressBar } from "@/features/common";
import { SkillAssessmentAddModal } from "@/features/skill";
import {
  JobOfferWizardModal,
  useJobOfferEnableProgress,
} from "@/features/job-offer";

export function JobOfferChecklist() {
  const { loading, assessedSkillCount, jobOffersEnabled } =
    useJobOfferEnableProgress();
  const [openWizardModal, setOpenWizardModal] = useState(false);
  const [openSkillAssessmentModal, setOpenSkillAssessmentModal] =
    useState(false);

  if (loading && jobOffersEnabled === undefined) {
    return null;
  }

  return (
    <>
      {!jobOffersEnabled ? (
        <JobOfferChecklistContainer>
          <Title>Complete your profile to get discovered by companies</Title>
          <ProgressBar
            value={(100 / 3) * assessedSkillCount}
            color="orange-100"
            backgroundColor="peach-70"
            height="5px"
          />

          <Checklist>
            <ChecklistItem
              done={assessedSkillCount > 0}
              onClick={() =>
                assessedSkillCount > 0
                  ? undefined
                  : setOpenSkillAssessmentModal(true)
              }
            >
              <DSCheckbox checked={assessedSkillCount > 0} readOnly />
              Complete your 1st test
            </ChecklistItem>
            <ChecklistItem
              done={assessedSkillCount > 1}
              onClick={() =>
                assessedSkillCount > 1
                  ? undefined
                  : setOpenSkillAssessmentModal(true)
              }
            >
              <DSCheckbox checked={assessedSkillCount > 1} readOnly />
              Complete your 2nd test
            </ChecklistItem>
            <ChecklistItem
              done={assessedSkillCount > 2}
              onClick={() =>
                assessedSkillCount > 2
                  ? undefined
                  : setOpenSkillAssessmentModal(true)
              }
            >
              <DSCheckbox checked={assessedSkillCount > 2} readOnly />
              Complete your 3rd test
            </ChecklistItem>
          </Checklist>

          <ActionButton
            variant="secondary"
            size="xsmall"
            disabled={assessedSkillCount < 3}
            onClick={() => setOpenWizardModal(true)}
          >
            Enable Job Offers
          </ActionButton>

          <SkillAssessmentAddModal
            open={openSkillAssessmentModal}
            onClose={() => setOpenSkillAssessmentModal(false)}
          />
        </JobOfferChecklistContainer>
      ) : null}

      <JobOfferWizardModal
        isOpen={openWizardModal}
        onClose={() => setOpenWizardModal(false)}
      />
    </>
  );
}

const JobOfferChecklistContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 12px;
  border-radius: 8px;
  background: ${(props) => props.theme.colors.purple[90]};
  box-shadow: ${(props) => props.theme.shadow.card};
  margin: 0 16px 24px;
`;

const Title = styled.div`
  font-size: 12px;
  font-weight: 700;
  color: ${(props) => props.theme.colors.peach[70]};
`;

const Checklist = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const ChecklistItem = styled.div<{ done: boolean }>`
  display: flex;
  gap: 8px;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  color: ${(props) => props.theme.colors.peach[70]};
  text-decoration: ${(props) => (props.done ? "line-through" : "none")};
  opacity: ${(props) => (props.done ? "0.4" : "1")};
  cursor: ${(props) => (props.done ? "default" : "pointer")};

  :hover {
    color: ${(props) =>
      props.done
        ? props.theme.colors.peach[70]
        : props.theme.colors.orange[70]}};
  }
`;

const ActionButton = styled(DSButton)`
  width: 100%;
`;
