import React from "react";
import styled from "@emotion/styled";
import { device } from "@/styles/breakpoints";
import { DSModal } from "@hundred5/design-system";
import { JobOfferWizard } from "@/features/job-offer";
import { useMedia } from "react-use";

interface JobOfferWizardModalProps {
  isOpen: boolean;
  isEditing?: boolean;
  onClose: () => void;
}

export function JobOfferWizardModal({
  isOpen,
  isEditing,
  onClose,
}: JobOfferWizardModalProps) {
  const isTablet = useMedia(device.tablet);

  return (
    <DSModal
      open={isOpen}
      onClose={onClose}
      contentStyle={{
        padding: 0,
        overflow: "hidden",
        maxWidth: !isTablet && "600px",
      }}
    >
      <CancelButton onClick={onClose}>Cancel</CancelButton>
      <CloseButton>
        <DSModal.CloseButton fixed small onClick={onClose} />
      </CloseButton>
      <Content>
        <JobOfferWizard isEditing={isEditing} onComplete={onClose} />
      </Content>
    </DSModal>
  );
}

const CancelButton = styled.button`
  all: unset;
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;
  position: absolute;
  top: 24px;
  left: 16px;
  z-index: 2;
  display: none;

  @media ${device.tablet} {
    display: block;
  }
`;

const CloseButton = styled.span`
  button {
    z-index: 2;
    display: block;

    @media ${device.tablet} {
      display: none;
    }
  }
`;

const Content = styled.div`
  max-height: calc(var(--view-height) - 80px);
  overflow-y: auto;

  @media ${device.tablet} {
    max-height: calc(var(--view-height) - 40px);
  }
`;
