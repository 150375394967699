import React from "react";
import { useRouter } from "next/router";
import styled from "@emotion/styled";
import { device } from "@/styles/breakpoints";
import { TogglHireLogo } from "@/features/common";

export function TogglHireLogoButton() {
  const router = useRouter();

  return (
    <LogoButton onClick={() => router.push("/")}>
      <TogglHireLogo type="icon" />
    </LogoButton>
  );
}

const LogoButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-appearance: none;
  cursor: pointer;
  outline: none;
  border: none;
  background: none;
  width: 24px;
  height: 24px;
  padding: 0;
  margin-bottom: 12px;

  @media ${device.tablet} {
    display: none;
  }
`;
