import React from "react";
import styled from "@emotion/styled";
import languageList from "language-list";
import { Form, Formik } from "formik";
import { faArrowLeft, faArrowRight } from "@fortawesome/pro-regular-svg-icons";
import { DSButton } from "@hundred5/design-system";
import { FormikComboboxMultiple, Icon, useWizard } from "@/features/common";
import { IJobOfferWizard } from "@/features/job-offer";
import { StepHeader, StepContent, StepFooter } from ".";

export function WizardStepLanguage() {
  const { data, setData, goBack, goForward } = useWizard<IJobOfferWizard>();
  const languages = languageList().getData();

  const handleForward = (values: any) => {
    setData({ ...data, ...values });
    goForward();
  };

  return (
    <Formik
      onSubmit={handleForward}
      initialValues={{
        languageCodes: data.languageCodes,
      }}
      enableReinitialize={true}
    >
      {({ isSubmitting }) => (
        <Form data-recording-ignore="mask">
          <StepHeader
            title="What languages can you work in?"
            description="Add any languages other than English you are comfortable working in"
          />

          <StepContent>
            <FormikComboboxMultiple
              label="Language"
              name="languageCodes"
              placeholder="Type to search..."
              items={languages.map((language) => ({
                id: language.code,
                label: language.language,
              }))}
              maxDropdownHeight="152px"
            />
            <Note>
              Can’t find a language you speak?{" "}
              <a
                href={`mailto:${process.env.NEXT_PUBLIC_SUPPORT_EMAIL}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                Let us know
              </a>
            </Note>
          </StepContent>

          <StepFooter>
            <DSButton variant="secondary" onClick={goBack}>
              <Icon icon={faArrowLeft} />
            </DSButton>
            <DSButton type="submit" disabled={isSubmitting}>
              Next <Icon icon={faArrowRight} color="white" />
            </DSButton>
          </StepFooter>
        </Form>
      )}
    </Formik>
  );
}

const Note = styled.div`
  margin-top: 8px;
  font-size: 12px;
  color: ${(props) => props.theme.typography.colorSecondary};
  text-align: right;

  a {
    color: inherit;
    text-decoration: underline;
  }
`;
