import React, { useRef, useState } from "react";
import styled from "@emotion/styled";
import { DSButton } from "@hundred5/design-system";
import { faXmark } from "@fortawesome/pro-regular-svg-icons";
import {
  ALLOWED_FILE_TYPES,
  getFileIcon,
  Icon,
  isFileSizeValid,
  useFlashMessages,
} from "@/features/common";

interface FileUploadProps {
  onChange: (file: File | null) => void;
  fileName?: string;
  maxSizeMB?: number;
  disabled?: boolean;
}

export function FileUpload({
  onChange,
  fileName,
  maxSizeMB = 15,
  disabled,
}: FileUploadProps) {
  const { addMessage } = useFlashMessages();
  const inputRef = useRef<HTMLInputElement>(null);
  const [filename, setFilename] = useState<string | null>(fileName || null);

  return (
    <Content>
      {filename ? (
        <FilePill disabled={disabled}>
          <Icon icon={getFileIcon(filename)} fontSize="10px" />
          <FileName data-recording-sensitive>{filename}</FileName>
          {!disabled && (
            <RemoveButton
              onClick={() => {
                setFilename(null);
                onChange(null);
              }}
            >
              <Icon icon={faXmark} fontSize="10px" />
            </RemoveButton>
          )}
        </FilePill>
      ) : (
        <DSButton
          variant="secondary"
          onClick={() => inputRef.current?.click()}
          disabled={disabled}
        >
          Upload file
          <FileInput
            ref={inputRef}
            type="file"
            accept={ALLOWED_FILE_TYPES}
            onChange={async (event) => {
              const files = event.target.files!;

              if (files.length) {
                const file = files[0];

                if (!isFileSizeValid(file, maxSizeMB)) {
                  addMessage({
                    type: "upload_file_size_error",
                    maxSizeMB,
                  });
                } else {
                  event.target.value = "";
                  setFilename(file.name);
                  onChange(file);
                }
              }
            }}
          />
        </DSButton>
      )}
    </Content>
  );
}

const Content = styled.div`
  display: inline-flex;
  align-items: center;
  height: 36px;
`;

const FileInput = styled.input`
  display: none;
`;

const RemoveButton = styled.button`
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  opacity: 0.4;
  transition: opacity 0.1s;
  height: 100%;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const FilePill = styled.div<{ disabled?: boolean }>`
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  padding-left: 8px;
  padding-right: ${(props) => (props.disabled ? "8px" : "0px")};
  height: 24px;
  background: ${(props) => props.theme.colors.white};
  border: 1px solid ${(props) => props.theme.colors.purple[40]};
  border-radius: 8px;
  font-weight: 500;
  font-size: 12px;
  color: ${(props) => props.theme.typography.colorPrimary};

  &:hover ${RemoveButton} {
    opacity: 1;
  }

  & > svg {
    margin-right: 4px;
  }
`;

const FileName = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
