import { useEffect, useMemo } from "react";
import { useJobOfferEnableProgressQuery } from "@/generated/graphql";
import { useApiErrorHandler } from "@/features/error";

export const useJobOfferEnableProgress = () => {
  const handleError = useApiErrorHandler();
  const [{ data, error, fetching, stale }] = useJobOfferEnableProgressQuery();

  const assessedSkillCount = useMemo(() => {
    return (
      data?.me.skills?.reduce(
        (total, item) => (item.isAssessed ? total + 1 : total),
        0
      ) || 0
    );
  }, [data?.me.skills]);

  useEffect(() => {
    if (!error) return;
    handleError(error);
  }, [handleError, error]);

  return {
    loading: fetching || stale,
    assessedSkillCount,
    jobOffersEnabled: data?.jobOfferSettings?.enabled,
  };
};
