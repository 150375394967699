import React from "react";
import styled from "@emotion/styled";
import { useRouter } from "next/router";
import { useMedia } from "react-use";
import { useLayout } from "@/features/layout";
import { device } from "@/styles/breakpoints";
import { Icon } from "@/features/common";
import { faCirclePlus } from "@fortawesome/pro-solid-svg-icons";

export function LayoutSidebarDashboardButton() {
  const router = useRouter();
  const isTablet = useMedia(device.tablet);
  const { setSidebarCollapsed } = useLayout();

  const handleClick = () => {
    if (isTablet) setSidebarCollapsed(true);
    router.push("/");
  };

  return (
    <DashboardButton active={router.pathname === "/"} onClick={handleClick}>
      <Icon icon={faCirclePlus} color="peach-70" />
      Dashboard
    </DashboardButton>
  );
}

const DashboardButton = styled.button<{ active: boolean }>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  cursor: pointer;
  outline: none;
  border: none;
  width: 100%;
  height: 28px;
  padding: 0 16px;
  margin-bottom: 12px;
  font-weight: 400;
  font-size: 14px;
  color: ${(props) => props.theme.colors.peach[70]};
  background: ${(props) =>
    props.active ? props.theme.colors.orange[100] : "none"};
  transition: background 0.1s;
  gap: 8px;

  &:hover {
    background: ${(props) =>
      props.active
        ? props.theme.colors.orange[120]
        : props.theme.colors.purple[90]};
  }

  @media ${device.tablet} {
    margin-bottom: 24px;
    background: none;

    &:hover {
      background: ${(props) => props.theme.colors.purple[90]};
    }
  }
`;
