import styled from "@emotion/styled";
import { device } from "@/styles/breakpoints";

export const StepContent = styled.main`
  padding: 20px 36px;
  background-color: ${(props) => props.theme.colors.white};
  min-height: calc(min(calc(var(--view-height) - 296px), 320px));

  @media ${device.tablet} {
    padding: 20px 16px;
    min-height: calc(min(calc(var(--view-height) - 256px), 400px));
  }
`;
