import React from "react";
import Image from "next/image";
import togglHireIcon from "./images/toggl-hire-icon.svg";
import togglHireLogo from "./images/toggl-hire-logo.svg";

interface TogglHireLogoProps {
  type: "icon" | "logo";
  width?: number;
}

export function TogglHireLogo({ type, width }: TogglHireLogoProps) {
  switch (type) {
    case "icon":
      return <Image src={togglHireIcon} alt="icon" width={width || 24} />;
    case "logo":
      return <Image src={togglHireLogo} alt="logo" width={width || 90} />;
    default:
      return null;
  }
}
