import React, { useCallback, useEffect } from "react";
import styled from "@emotion/styled";
import languageList from "language-list";
import { getTimeZones } from "@vvo/tzdb";
import { device } from "@/styles/breakpoints";
import { faCircleQuestion } from "@fortawesome/pro-regular-svg-icons";
import { JobOfferSettings, usePositionNamesQuery } from "@/generated/graphql";
import { useApiErrorHandler } from "@/features/error";
import { Icon, useCountryList } from "@/features/common";
import {
  getJobOfferSeniorityLabel,
  salaryNumberToString,
  sortSeniorityLevels,
} from "@/features/job-offer";

interface PreferencesProps {
  contactEmail?: string;
  jobOfferSettings?: JobOfferSettings | null;
}

export function Preferences({
  contactEmail,
  jobOfferSettings,
}: PreferencesProps) {
  const handleError = useApiErrorHandler();
  const { getCountryName } = useCountryList();
  const timeZones = getTimeZones();
  const languages = languageList();
  const [
    { data: positionsData, fetching: fetchingPositions, error: positionsError },
  ] = usePositionNamesQuery({ pause: !jobOfferSettings?.positionIDs?.length });

  useEffect(() => {
    if (!positionsError) return;
    handleError(positionsError);
  }, [handleError, positionsError]);

  const getCountryNames = useCallback(
    (countryCodes: string[]) => {
      return countryCodes.map(getCountryName).join(", ");
    },
    [getCountryName]
  );

  const getTimezoneFullName = useCallback(
    (timezoneName: string) => {
      const timezone = timeZones.find(
        (timezone) => timezone.name === timezoneName
      )?.rawFormat;

      return timezone ? `GMT${timezone}` : "";
    },
    [timeZones]
  );

  const getLanguageNames = useCallback(
    (languageCodes: string[]) => {
      return languageCodes.map(languages.getLanguageName).join(", ");
    },
    [languages]
  );

  const getPositionNames = useCallback(
    (positionIDs: string[]) => {
      return positionIDs
        .map(
          (id) =>
            positionsData?.positions?.find((position) => position.id === id)
              ?.name
        )
        .sort();
    },
    [positionsData?.positions]
  );

  return (
    <PreferencesContainer>
      <Column>
        {(!!jobOfferSettings?.countryCodes.length ||
          !!jobOfferSettings?.remote) && (
          <Section>
            <Title>Location</Title>
            <Details>
              <div>{getCountryNames(jobOfferSettings.countryCodes)}</div>
              {!!jobOfferSettings.remote && <div>Remote</div>}
            </Details>
          </Section>
        )}

        {!!jobOfferSettings?.timezoneName && (
          <Section>
            <Title>Time zone</Title>
            <Details>
              {getTimezoneFullName(jobOfferSettings.timezoneName)}
            </Details>
          </Section>
        )}

        {!!jobOfferSettings?.languageCodes.length && (
          <Section>
            <Title>Languages</Title>
            <Details>
              {getLanguageNames(jobOfferSettings.languageCodes)}
            </Details>
          </Section>
        )}

        <Section>
          <Title>
            Email
            <Icon
              icon={faCircleQuestion}
              color="purple-60"
              fontSize="12px"
              data-rh="Job notifications will be sent to this email. It might be different from the one you use to log in."
              data-rh-at="right"
            />
          </Title>
          <Details>{contactEmail}</Details>
        </Section>
      </Column>

      <Column>
        {!!jobOfferSettings?.positionIDs.length && !fetchingPositions && (
          <Section>
            <Title>Roles</Title>
            <Details>
              {getPositionNames(jobOfferSettings.positionIDs).map(
                (position) => (
                  <div key={position}>{position}</div>
                )
              )}
            </Details>
          </Section>
        )}

        {!!jobOfferSettings?.seniorityLevels.length && (
          <Section>
            <Title>Seniority</Title>
            <Details>
              {sortSeniorityLevels(jobOfferSettings.seniorityLevels).map(
                (seniorityLevel) => (
                  <div key={seniorityLevel}>
                    {getJobOfferSeniorityLabel(seniorityLevel)}
                  </div>
                )
              )}
            </Details>
          </Section>
        )}

        {!!jobOfferSettings?.salary && (
          <Section>
            <Title>Annual salary expectation</Title>
            <Details>
              {salaryNumberToString(jobOfferSettings.salary)}{" "}
              {jobOfferSettings.salaryCurrency}
            </Details>
          </Section>
        )}
      </Column>
    </PreferencesContainer>
  );
}

const PreferencesContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 60px;

  @media ${device.tablet} {
    grid-template-columns: 1fr;
    gap: 16px;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Title = styled.div`
  color: ${(props) => props.theme.typography.colorPrimary};
  font-size: 14px;
  font-weight: 700;
  display: flex;
  align-items: center;
  gap: 4px;
`;

const Details = styled.div`
  color: ${(props) => props.theme.typography.colorPrimary};
  font-size: 14px;
  font-weight: 400;
`;
