import React from "react";
import ReactHintFactory from "react-hint";
import styled from "@emotion/styled";
import "react-hint/css/index.css";

const ReactHint = ReactHintFactory(React);

type Props = {
  attribute?: string;
  delay?: { show: number; hide: number } | number;
};

export function Tooltip({ attribute, delay = 200 }: Props) {
  return (
    <TooltipWrapper>
      <ReactHint
        events
        autoPosition
        attribute={attribute}
        delay={delay}
        persist
      />
    </TooltipWrapper>
  );
}

const TooltipWrapper = styled.div`
  .react-hint {
    max-width: 328px;
    width: max-content;
    z-index: 99999;
  }

  .react-hint .react-hint__content {
    padding: 8px 16px;
    border-radius: 8px;
    background: ${(props) => props.theme.colors.white};
    font-size: 12px;
    color: ${(props) => props.theme.typography.colorPrimary};
    box-shadow: ${(props) => props.theme.shadow.popup};
    white-space: pre-wrap;
    overflow-wrap: break-word;
  }

  .react-hint--top:after {
    border-top-color: ${(props) => props.theme.colors.white};
    filter: drop-shadow(${(props) => props.theme.shadow.popup});
  }

  .react-hint--left:after {
    border-left-color: ${(props) => props.theme.colors.white};
    filter: drop-shadow(${(props) => props.theme.shadow.popup});
  }

  .react-hint--right:after {
    border-right-color: ${(props) => props.theme.colors.white};
    filter: drop-shadow(${(props) => props.theme.shadow.popup});
  }

  .react-hint--bottom:after {
    border-bottom-color: ${(props) => props.theme.colors.white};
    filter: drop-shadow(${(props) => props.theme.shadow.popup});
  }

  .react-hint--top {
    margin-top: -4px;
  }

  .react-hint--left {
    margin-left: -4px;
  }

  .react-hint--right {
    margin-left: 4px;
  }

  .react-hint--bottom {
    margin-top: 4px;
  }
`;
