import React, { useMemo } from "react";
import { useField } from "formik";
import { uniqueId } from "lodash";
import { DSField } from "@hundred5/design-system";
import { FileUpload } from "@/features/common";

interface FormikUploadProps {
  name: string;
  label: React.ReactNode;
  fileName?: string;
  required?: boolean;
  id?: string;
  disabled?: boolean;
  errorAbsolute?: boolean;
  maxSizeMB?: number;
}

export function FormikUpload(props: FormikUploadProps) {
  const [field, meta, helpers] = useField<any>(props.name);
  const error = meta.touched && !!meta.error ? meta.error : null;
  const id = useMemo(() => props.id || uniqueId("formik-field-"), [props.id]);

  return (
    <DSField
      for={id}
      label={props.label}
      required={props.required}
      error={error}
      errorAbsolute={props.errorAbsolute}
    >
      <FileUpload
        {...field}
        onChange={(file) => helpers.setValue(file)}
        fileName={props.fileName}
        maxSizeMB={props.maxSizeMB}
        disabled={props.disabled}
      />
    </DSField>
  );
}
