import React from "react";
import styled from "@emotion/styled";
import { faArrowLeft } from "@fortawesome/pro-regular-svg-icons";
import { Icon } from "../icon";

interface BackButtonProps {
  onClick: () => void;
}

export function BackButton({ onClick }: BackButtonProps) {
  return (
    <CircleButton onClick={onClick}>
      <Icon icon={faArrowLeft} color="purple-60" fontSize="12px" />
    </CircleButton>
  );
}

const CircleButton = styled.button`
  width: 36px;
  height: 36px;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  -webkit-appearance: none;
  text-align: center;
  cursor: pointer;
  outline: none;
  border: 1px solid ${(props) => props.theme.colors.purple[60]};
  background: ${(props) => props.theme.colors.white};
  transition: background 0.1s, transform 0.1s;
  &:hover {
    background: ${(props) => props.theme.hover.onWhite};
  }

  &:active {
    transform: scale(0.96);
  }
`;
