import React from "react";
import { DSButton } from "@hundred5/design-system";
import { useSkillAssessmentStartMutation } from "@/generated/graphql";
import { useAmplitude } from "@/features/analytics";
import { useApiErrorHandler } from "@/features/error";

interface SkillAssessmentStartButtonProps {
  openingSlug: string;
  testSlug: string;
  skillId: string;
  skillName: string;
  retake?: boolean;
}

export function SkillAssessmentStartButton({
  openingSlug,
  testSlug,
  skillId,
  skillName,
  retake,
}: SkillAssessmentStartButtonProps) {
  const handleError = useApiErrorHandler();
  const { logEvent } = useAmplitude();
  const [, startAssessment] = useSkillAssessmentStartMutation();

  const handleStartAssessment = async () => {
    const result = await startAssessment({
      openingSlug: openingSlug,
      testSlug: testSlug,
    });

    if (result.error) {
      handleError(result.error);
    } else {
      logEvent(
        `assessment/${retake ? "test again" : "take test"}`,
        {
          "skill id": skillId,
          "skill name": skillName,
          "test slug": testSlug,
          "opening slug": openingSlug,
        },
        () =>
          window.open(
            `${process.env.NEXT_PUBLIC_CANDIDATE_APP_URL}/${openingSlug}/${testSlug}?candidate_token=${result.data?.startAssessment?.token}`,
            "_self"
          )
      );
    }
  };
  return (
    <DSButton variant="secondary" size="small" onClick={handleStartAssessment}>
      {retake ? "Test again" : "Take test"}
    </DSButton>
  );
}
