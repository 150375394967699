import React, { useRef, useState } from "react";
import useOnClickOutside from "use-onclickoutside";
import styled from "@emotion/styled";
import { device } from "@/styles/breakpoints";
import { faChevronDown } from "@fortawesome/pro-solid-svg-icons";
import { DSContextMenu } from "@hundred5/design-system";
import { Icon } from "@/features/common";
import { TMenuItem } from "./layout-sidebar-user-menu.types";

interface LayoutSidebarUserMenuViewProps {
  userFirstName?: string;
  userLastName?: string;
  menuItems: TMenuItem[];
}

export function LayoutSidebarUserMenuView({
  userFirstName,
  userLastName,
  menuItems,
}: LayoutSidebarUserMenuViewProps) {
  const menuRef = useRef<null | HTMLDivElement>(null);
  const [menuOpen, setMenuOpen] = useState(false);
  useOnClickOutside(menuRef, () => setMenuOpen(false));

  return (
    <LayoutSidebarUserMenuContainer ref={menuRef}>
      <MenuButton onClick={() => setMenuOpen(!menuOpen)}>
        <Title>
          Candidate Portal (beta)
          <ChevronIcon
            isOpen={menuOpen}
            icon={faChevronDown}
            color="white"
            fontSize="10px"
          />
        </Title>
        <UserName data-recording-sensitive>
          {userFirstName} {userLastName}
        </UserName>
      </MenuButton>

      <ContextMenu open={menuOpen} position={{ left: "0" }}>
        {menuItems.map((item) => (
          <DSContextMenu.Item
            key={item.label}
            label={item.label}
            onClick={item.onClick}
            dangerous={item.dangerous}
          />
        ))}
      </ContextMenu>
    </LayoutSidebarUserMenuContainer>
  );
}

const LayoutSidebarUserMenuContainer = styled.div`
  position: relative;
  margin-bottom: 14px;
  background: ${(props) => props.theme.colors.purple[100]};

  @media ${device.tablet} {
    margin-bottom: 16px;
  }
`;

const MenuButton = styled.button`
  -webkit-appearance: none;
  cursor: pointer;
  outline: none;
  border: none;
  background: none;
  color: ${(props) => props.theme.colors.white};
  padding: 16px 16px 12px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  transition: background 0.1s;

  &:hover {
    background: ${(props) => props.theme.colors.purple[90]};
  }

  @media ${device.tablet} {
    padding: 25px 16px 12px;
  }
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 16px;
`;

const ChevronIcon = styled(Icon, {
  shouldForwardProp: (prop) => !["isOpen"].includes(prop),
})<{ isOpen: boolean }>`
  margin-left: 8px;
  transform: rotate(${({ isOpen }) => (isOpen ? 180 : 0)}deg);
`;

const UserName = styled.div`
  font-weight: 400;
  font-size: 14px;
  color: ${(props) => props.theme.colors.white};
`;

const ContextMenu = styled(DSContextMenu)`
  width: 85%;
  top: calc(100% + 4px);

  @media ${device.tablet} {
    padding: 8px;
  }
`;
