const formatDistanceLocale: any = {
  lessThanXSeconds: {
    one: "a second",
    other: "{{count}}s",
  },

  xSeconds: {
    one: "1s",
    other: "{{count}}s",
  },

  halfAMinute: "30s",

  lessThanXMinutes: {
    one: "1min",
    other: "{{count}}min",
  },

  xMinutes: {
    one: "1min",
    other: "{{count}}min",
  },

  aboutXHours: {
    one: "1h",
    other: "{{count}}h",
  },

  xHours: {
    one: "1h",
    other: "{{count}}h",
  },

  xDays: {
    one: "1 day",
    other: "{{count}} days",
  },

  aboutXWeeks: {
    one: "1w",
    other: "{{count}}w",
  },

  xWeeks: {
    one: "1w",
    other: "{{count}}w",
  },

  aboutXMonths: {
    one: "1 month",
    other: "{{count}} months",
  },

  xMonths: {
    one: "1 month",
    other: "{{count}} months",
  },

  aboutXYears: {
    one: "1 year",
    other: "{{count}} years",
  },

  xYears: {
    one: "1 year",
    other: "{{count}} years",
  },

  overXYears: {
    one: "1 year",
    other: "{{count}} years",
  },

  almostXYears: {
    one: "1 year",
    other: "{{count}} years",
  },
};

export const formatDistance: (...args: Array<any>) => any = (
  token,
  count,
  options
) => {
  let result;

  const tokenValue = formatDistanceLocale[token];

  if (typeof tokenValue === "string") {
    result = tokenValue;
  } else if (count === 1) {
    result = tokenValue.one;
  } else {
    result = tokenValue.other.replace("{{count}}", count.toString());
  }

  if (options?.addSuffix) {
    if (options?.comparison && options.comparison > 0) {
      return "in " + result;
    } else {
      return result + " ago";
    }
  }

  return result;
};
