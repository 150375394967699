import amplitudeClient, { AmplitudeClient } from "amplitude-js";
import { userHasConsentedToMarketing } from "@/utils/cookie-permissions";

export let amplitude: AmplitudeClient | undefined = undefined;

function startAmplitude() {
  if (
    typeof window !== "undefined" &&
    !amplitude &&
    process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY &&
    userHasConsentedToMarketing()
  ) {
    amplitude = amplitudeClient.getInstance();

    amplitude.init(process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY, undefined, {
      includeReferrer: true,
      includeUtm: true,
    });
  }
}

// Try to start amplitude as soon as window loads
startAmplitude();

// add event listener to start amplitude when user consent is ready
// this is needed because the user consent might not be ready when window loads
if (typeof window !== "undefined") {
  window.addEventListener("CookiebotOnConsentReady", startAmplitude);
}
