import { useMemo } from "react";
import { getData, getName } from "country-list";

const COUNTRIES = getData();
const EUROPEAN_UNION = { code: "EU", name: "European Union" };

export function useCountryList(withEU?: boolean) {
  const countries = useMemo(() => {
    const sortedCountries = COUNTRIES.sort((a, b) =>
      a.name.localeCompare(b.name, undefined, { sensitivity: "base" })
    );

    if (withEU) {
      return [EUROPEAN_UNION, ...sortedCountries];
    }

    return sortedCountries;
  }, [withEU]);

  const getCountryName = (code: string) => {
    if (code === EUROPEAN_UNION.code) {
      return EUROPEAN_UNION.name;
    }

    return getName(code);
  };

  return { countries, getCountryName };
}
