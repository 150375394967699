import React from "react";
import styled from "@emotion/styled";
import { DSButton } from "@hundred5/design-system";
import { device } from "@/styles/breakpoints";

interface DisabledProps {
  canEnable: boolean;
  onEnable: () => void;
}

export function Disabled({ canEnable, onEnable }: DisabledProps) {
  return (
    <DisabledContainer>
      <Title>Job Offers</Title>
      <Description>
        Opt in to Job Offers to receive personalized job matches
      </Description>
      <Action>
        <DSButton
          onClick={onEnable}
          disabled={!canEnable}
          data-rh={
            !canEnable
              ? "Take 3 skills tests to complete your Skills Profile and get discovered by companies"
              : undefined
          }
          data-rh-at="top"
        >
          Enable Job Offers
        </DSButton>
      </Action>
    </DisabledContainer>
  );
}

const DisabledContainer = styled.div`
  background-color: ${(props) => props.theme.colors.white};
  border-radius: 8px;
  padding: 36px;
  display: grid;
  grid-template-areas: "title action" "description action";
  grid-auto-columns: auto min-content;
  grid-column-gap: 16px;
  grid-row-gap: 4px;

  @media ${device.tablet} {
    border-radius: unset;
    padding: 16px;
    grid-template-areas: "title" "description" "action";
  }
`;

const Title = styled.div`
  grid-area: title;
  font-size: 16px;
  font-weight: 700;
  color: ${(props) => props.theme.typography.colorPrimary};
`;

const Description = styled.div`
  grid-area: description;
  font-size: 14px;
  font-weight: 400;
  color: ${(props) => props.theme.typography.colorSecondary};
`;

const Action = styled.div`
  grid-area: action;
  place-self: center flex-end;

  @media ${device.tablet} {
    place-self: auto;
    padding-top: 12px;

    & button {
      width: 100%;
    }
  }
`;
