import React, { useState } from "react";
import styled from "@emotion/styled";
import { DSInlineField, DSCheckbox } from "@hundred5/design-system";
import { LoginButton } from "./ui";
import { LoginProviders, useLogIn } from "@/features/auth";

export function LoginForm() {
  const allowedMethods: LoginProviders[] = ["github", "linkedin"];
  const [acceptTerms, setAcceptTerms] = useState(false);
  const logIn = useLogIn();

  if (!!Number(process.env.NEXT_PUBLIC_ENABLE_TEST_LOGIN)) {
    allowedMethods.push("test");
  }

  return (
    <Container>
      <Terms>
        <DSInlineField
          for="login-form-terms"
          label={
            <>
              I agree with Toggl Hire{" "}
              <a
                href="https://toggl.com/hire/candidate-terms"
                target="_blank"
                rel="noreferrer noopener"
              >
                Terms & Conditions
              </a>
              *
            </>
          }
        >
          <DSCheckbox
            type="checkbox"
            id="login-form-terms"
            checked={acceptTerms}
            onChange={(event) => {
              setAcceptTerms(event.target.checked);
            }}
          />
        </DSInlineField>
      </Terms>

      <Buttons>
        {allowedMethods.map((method) => (
          <LoginButton
            key={method}
            method={method}
            disabled={!acceptTerms}
            onLogin={(email?: string) => logIn(method, email)}
          />
        ))}
      </Buttons>

      <Note>
        Using social logins is safe: nothing will be posted in your feeds.
      </Note>
    </Container>
  );
}

const Container = styled.div`
  text-align: center;
  max-width: 420px;
`;

const Terms = styled.div`
  margin-bottom: 20px;

  div > div {
    justify-content: center;
  }
`;

const Buttons = styled.div`
  width: 100%;
  display: grid;
  grid-row-gap: 16px;
  margin-bottom: 20px;
`;

const Note = styled.div`
  font-style: italic;
`;
