import React from "react";
import styled from "@emotion/styled";
import { device } from "@/styles/breakpoints";
import { Icon } from "@/features/common";
import {
  faChevronsLeft,
  faChevronsRight,
} from "@fortawesome/pro-regular-svg-icons";

interface ToggleSidebarButtonProps {
  active: boolean;
  onClick: () => void;
}

export function ToggleSidebarButton({
  active,
  onClick,
}: ToggleSidebarButtonProps) {
  return (
    <ToggleButton
      data-sidebar-tooltip={active ? "Close sidebar" : "Lock sidebar open"}
      data-sidebar-tooltip-at="right"
      onClick={onClick}
    >
      <Icon
        icon={active ? faChevronsLeft : faChevronsRight}
        color="purple-40"
        fontSize="16px"
      />
    </ToggleButton>
  );
}

const ToggleButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-appearance: none;
  cursor: pointer;
  outline: none;
  border: none;
  background: none;
  width: 100%;
  height: 32px;
  padding: 0;
  transition: background 0.1s;

  &:hover {
    background: ${(props) => props.theme.colors.purple[100]};
  }

  @media ${device.tablet} {
    display: none;
  }
`;
