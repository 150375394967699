import {
  faFileCircleQuestion,
  faFileImage,
  faFileLines,
  faFilePdf,
  faFileSpreadsheet,
} from "@fortawesome/pro-regular-svg-icons";

export const ALLOWED_FILE_TYPES =
  ".doc, .docx, .xls, .xlsx, .txt, .pdf, .jpg, .jpeg, .png, .odt";

export const ALLOWED_IMAGE_FILE_TYPES = ".jpg, .jpeg, .png";

export function getFileIcon(fileName: string) {
  return /.png$|.jpg$|.jpeg$/.test(fileName)
    ? faFileImage
    : /.xls$|.xlsx$/.test(fileName)
    ? faFileSpreadsheet
    : /.doc$|.docx$|.txt$|.odt$/.test(fileName)
    ? faFileLines
    : /.pdf$/.test(fileName)
    ? faFilePdf
    : faFileCircleQuestion;
}

export function isFileSizeValid(file: File, maxSizeMB: number) {
  return file.size / 1024 / 1024 <= maxSizeMB;
}
