import React, { useMemo } from "react";
import styled from "@emotion/styled";
import { useColor } from "@/features/common";
import { device } from "@/styles/breakpoints";

interface ProgressBlocksProps {
  /**
   * Percentual value of how much the progress blocks are filled out of 100%
   */
  value?: number;
  /**
   * Color of the filled progress blocks.
   * If color from theme is used, then format -> $name-$value
   */
  color?: string;
}

export function ProgressBlocks({ value, color }: ProgressBlocksProps) {
  const fillColor = useColor(color, "orange-100");

  const blockFillPercentages = useMemo(() => {
    const validValue = Math.min(Math.max(value || 0, 0), 100);
    const filled = Math.floor(validValue / 10);
    const reminder = validValue % 10;

    const percentages = Array.from<number>({ length: 10 })
      .fill(100, 0, filled)
      .fill((reminder / 10) * 100, filled, filled + 1)
      .fill(0, filled + 1);

    return percentages;
  }, [value]);

  return (
    <ProgressBlocksContainer>
      {blockFillPercentages.map((blockValue, indx) => (
        <BlockBackground key={indx}>
          <BlockFilled value={blockValue} color={fillColor} />
        </BlockBackground>
      ))}
    </ProgressBlocksContainer>
  );
}

const ProgressBlocksContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: repeat(9, minmax(26px, 30px)) 22px;

  @media ${device.tablet} {
    grid-template-columns: repeat(9, minmax(24px, 28px)) 20px;
  }
`;

const BlockBackground = styled.div`
  width: 22px;
  height: 22px;
  border-radius: 2px;
  overflow: hidden;
  background: ${(props) => props.theme.colors.purple[10]};

  @media ${device.tablet} {
    width: 20px;
    height: 20px;
  }
`;

const BlockFilled = styled.div<{ value: number; color: string }>`
  height: 100%;
  width: ${(props) => props.value}%;
  background: ${(props) => props.color};
`;
