import React from "react";
import styled from "@emotion/styled";
import { Form, Formik } from "formik";
import { array, object, string } from "yup";
import { faArrowLeft, faArrowRight } from "@fortawesome/pro-regular-svg-icons";
import { DSButton, DSField } from "@hundred5/design-system";
import { SeniorityLevel } from "@/generated/graphql";
import { Icon, useWizard } from "@/features/common";
import {
  IJobOfferWizard,
  getJobOfferSeniorityList,
} from "@/features/job-offer";
import { StepHeader, StepContent, StepFooter, SelectableItem } from ".";

const validationSchema = object().shape({
  seniorityLevels: array()
    .of(string())
    .min(1, "Select at least one option")
    .max(2, "Select at most two options"),
});

export function WizardStepSeniority() {
  const { data, setData, goBack, goForward } = useWizard<IJobOfferWizard>();
  const seniorityList = getJobOfferSeniorityList();

  const handleForward = (values: any) => {
    setData({ ...data, ...values });
    goForward();
  };

  const handleSeniorityClick = (
    selectedValues: SeniorityLevel[],
    id: SeniorityLevel
  ) => {
    const alreadySelected = selectedValues.includes(id);

    if (alreadySelected) {
      return selectedValues.filter((value) => value !== id);
    }

    return [...selectedValues, id];
  };

  return (
    <Formik
      onSubmit={handleForward}
      initialValues={{
        seniorityLevels: data.seniorityLevels,
      }}
      validationSchema={validationSchema}
      enableReinitialize={true}
      validateOnMount={true}
    >
      {({ values, touched, errors, setFieldValue, isValid, isSubmitting }) => (
        <Form data-recording-ignore="mask">
          <StepHeader
            title="What level of roles are you interested in?"
            description="Select the most relevant for you (max 2)"
          />

          <StepContent>
            <SeniorityField
              for="seniorityLevels"
              error={touched.seniorityLevels && errors.seniorityLevels}
            >
              {seniorityList.map((seniority) => (
                <SelectableItem
                  key={seniority.id}
                  id={seniority.id}
                  label={seniority.label}
                  selected={values.seniorityLevels.includes(seniority.id)}
                  onClick={(id) =>
                    setFieldValue(
                      "seniorityLevels",
                      handleSeniorityClick(
                        values.seniorityLevels,
                        id as SeniorityLevel
                      )
                    )
                  }
                  disabled={
                    values.seniorityLevels.length >= 2 &&
                    !values.seniorityLevels.includes(seniority.id)
                  }
                />
              ))}
            </SeniorityField>
          </StepContent>

          <StepFooter>
            <DSButton variant="secondary" onClick={goBack}>
              <Icon icon={faArrowLeft} />
            </DSButton>
            <DSButton type="submit" disabled={!isValid || isSubmitting}>
              Next <Icon icon={faArrowRight} color="white" />
            </DSButton>
          </StepFooter>
        </Form>
      )}
    </Formik>
  );
}

const SeniorityField = styled(DSField)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 8px;
`;
