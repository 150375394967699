import React from "react";
import styled from "@emotion/styled";
import { useFlashMessages } from "./flash-messages.utils";
import { Message } from "./ui";

export function FlashMessages() {
  const { messages } = useFlashMessages();

  return (
    <Container>
      {messages.map((message) => (
        <Message key={message.id} message={message} />
      ))}
    </Container>
  );
}

const Container = styled.div`
  position: fixed;
  bottom: 20px;
  right: 20px;
  margin-left: 20px;
  display: grid;
  grid-row-gap: 20px;
  z-index: 9999;
`;
