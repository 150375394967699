import React from "react";
import styled from "@emotion/styled";
import { LayoutProvider } from "@/features/layout";
import { LayoutHeader } from "../layout-header";
import { LayoutSidebar } from "../layout-sidebar";

/**
 * Layout contains LayoutHeader and LayoutSidebar.
 * Children property goes into main content.
 */

export function Layout({ children }: React.PropsWithChildren) {
  return (
    <LayoutProvider>
      <LayoutContainer>
        <LayoutHeader />
        <LayoutSidebar />
        <MainContent>{children}</MainContent>
      </LayoutContainer>
    </LayoutProvider>
  );
}

const LayoutContainer = styled.div`
  height: var(--view-height);
  display: grid;
  grid-template-areas: "header header" "sidebar main";
  grid-auto-columns: minmax(0, min-content) auto;
  grid-auto-rows: minmax(0, min-content) auto;
`;

const MainContent = styled.main`
  grid-area: main;
  overflow-y: auto;
`;
