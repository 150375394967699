import styled from "@emotion/styled";
import { device } from "@/styles/breakpoints";

export const StepFooter = styled.footer`
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  padding: 16px 36px 36px;
  background-color: ${(props) => props.theme.colors.white};

  @media ${device.tablet} {
    padding: 16px;

    & > div:last-of-type,
    button:last-of-type {
      width: 100%;
    }
  }
`;
