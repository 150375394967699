import React from "react";
import styled from "@emotion/styled";
import { useRouter } from "next/router";
import { useMedia } from "react-use";
import { device } from "@/styles/breakpoints";
import { Application } from "@/generated/graphql";
import { useLayout } from "@/features/layout";
import { ApplicationListItem, EmptyItem } from "./ui";

interface LayoutSidebarApplicationListViewProps {
  type: "active" | "past";
  applications: Partial<Application>[] | null;
}

export function LayoutSidebarApplicationListView({
  type,
  applications,
}: LayoutSidebarApplicationListViewProps) {
  const router = useRouter();
  const isTablet = useMedia(device.tablet);
  const { setSidebarCollapsed } = useLayout();

  const handleApplicationListItemClick = (applicationSlug: string) => {
    if (isTablet) setSidebarCollapsed(true);
    router.push(`/application/${applicationSlug}`);
  };

  return (
    <LayoutSidebarApplicationListContainer>
      <Title>{type === "active" ? "Active" : "Past"} applications</Title>
      <List>
        {applications?.map((application) => (
          <ApplicationListItem
            key={application.slug}
            applicationTitle={application.title!}
            workspaceName={application.workspace?.name || undefined}
            onClick={() => handleApplicationListItemClick(application.slug!)}
            active={router.query.id === application.slug}
          />
        ))}
        {(!applications || !applications.length) && <EmptyItem />}
      </List>
    </LayoutSidebarApplicationListContainer>
  );
}

const LayoutSidebarApplicationListContainer = styled.div`
  margin-bottom: 20px;
  background: ${(props) => props.theme.colors.purple[100]};
`;

const Title = styled.div`
  font-weight: 700;
  font-size: 12px;
  text-transform: uppercase;
  color: ${(props) => props.theme.colors.purple[60]};
  padding: 0 16px;
  margin-bottom: 6px;
`;

const List = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;
