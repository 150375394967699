import React from "react";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { startCase } from "lodash";
import { Icon } from "@/features/common";
import { DSButton } from "@hundred5/design-system";
import { CandidateSkill } from "@/generated/graphql";
import { useAmplitude } from "@/features/analytics";

interface LinkedInAddToProfileButtonProps {
  skill: CandidateSkill;
  candidateSlug: string;
}

export function LinkedInAddToProfileButton({
  skill,
  candidateSlug,
}: LinkedInAddToProfileButtonProps) {
  const { logEvent } = useAmplitude();

  return (
    <DSButton
      variant="secondary"
      size="small"
      Icon={<Icon icon={faLinkedin} />}
      onClick={() => {
        logEvent("linkedIn/add to profile", {
          "skill id": skill.id,
          "skill name": skill.name,
          score: skill.score,
          "candidate slug": candidateSlug,
        });
        window.open(
          encodeURI(
            `https://www.linkedin.com/profile/add?startTask=CERTIFICATION_NAME&name=Toggl Hire ${startCase(
              skill.name
            )} Certificate&organizationId=${
              process.env.NEXT_PUBLIC_LINKEDIN_ORGANIZATION_ID
            }&issueYear=${new Date().getFullYear()}&issueMonth=${
              new Date().getMonth() + 1
            }&certUrl=${
              process.env.NEXT_PUBLIC_APP_URL
            }/${candidateSlug}&certId=${candidateSlug}`
          ),
          "_blank"
        );
      }}
    >
      Add to profile
    </DSButton>
  );
}
