import React, { useState } from "react";
import styled from "@emotion/styled";
import { DSButton, DSField, DSInput } from "@hundred5/design-system";

interface TestLoginProps {
  loading: boolean;
  onLogin: (email: string) => void;
}

const isValidEmail = (value: string): boolean => /.+@.+\..+/.test(value);

export function TestLogin({ loading, onLogin }: TestLoginProps) {
  const [showEmailInput, setShowEmailInput] = useState(false);
  const [showError, setShowError] = useState(false);
  const [email, setEmail] = useState("");

  const handleLogIn = () => {
    if (!isValidEmail(email)) {
      setShowError(true);
      return;
    }
    onLogin(email);
  };

  return (
    <>
      {showEmailInput && (
        <StyledField
          for="email"
          label="Email"
          required
          error={
            showError && !isValidEmail(email)
              ? "Enter a valid email address"
              : undefined
          }
        >
          <DSInput
            id="email"
            value={email}
            disabled={loading}
            error={showError && !isValidEmail(email)}
            onChange={(e) => setEmail(e.currentTarget.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleLogIn();
              }
            }}
          />
        </StyledField>
      )}

      <StyledButton
        variant="secondary"
        disabled={loading}
        onClick={() => {
          if (showEmailInput) {
            handleLogIn();
          } else {
            setShowEmailInput(true);
          }
        }}
      >
        Log in for testing
      </StyledButton>
    </>
  );
}

const StyledField = styled(DSField)`
  align-items: flex-start;
`;

const StyledButton = styled(DSButton)`
  width: 100%;
`;
