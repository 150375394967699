import React, { useMemo } from "react";
import { useField } from "formik";
import { uniqueId } from "lodash";
import { DSButtonGroup, DSField } from "@hundred5/design-system";

type TItem = {
  id: string;
  label: React.ReactNode;
};

interface FormikButtonGroupProps {
  name: string;
  items: TItem[];
  label?: React.ReactNode;
  required?: boolean;
  id?: string;
  disabled?: boolean;
  errorAbsolute?: boolean;
}

export function FormikButtonGroup(props: FormikButtonGroupProps) {
  const [field, meta, helpers] = useField<any>(props.name);
  const error = meta.touched && !!meta.error ? meta.error : null;
  const id = useMemo(() => props.id || uniqueId("formik-field-"), [props.id]);

  return (
    <DSField
      for={id}
      label={props.label}
      required={props.required}
      error={error}
      errorAbsolute={props.errorAbsolute}
    >
      <DSButtonGroup
        id={id}
        {...field}
        items={props.items}
        selectedId={field.value}
        onChange={(id) => helpers.setValue(id)}
        disabled={props.disabled}
        error={!!error}
      />
    </DSField>
  );
}
