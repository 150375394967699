import React from "react";
import { PageTitle, TogglHireLogo } from "@/features/common";
import { LoginForm } from "@/features/auth";
import styled from "@emotion/styled";
import { device } from "@/styles/breakpoints";

//region Main
export function LoginPage() {
  return (
    <>
      <PageTitle title="Log in" />
      <Container>
        <MainContent>
          <LogoWrapper>
            <TogglHireLogo type="logo" width={158} />
          </LogoWrapper>

          <LoginWrapper>
            <Title>Log in to Candidate Portal</Title>
            <Message>See your application history and process</Message>
            <LoginForm />
          </LoginWrapper>
        </MainContent>
        <AsideContent>
          <AsideTitle>
            Skills over
            <br />
            resumes,
            <br />
            always.
          </AsideTitle>
          <ManifestoLink
            href="https://toggl.com/hire/manifesto"
            target="_blank"
            rel="noreferrer noopener"
          >{`See our Manifesto ->`}</ManifestoLink>
        </AsideContent>
      </Container>
    </>
  );
}
//endregion

//region Styles
const Container = styled.div`
  display: flex;
  justify-content: center;
  min-height: var(--view-height);
  background-color: ${({ theme }) => theme.colors.peach[10]};

  & > * {
    flex: 1;
  }
`;

const MainContent = styled.main`
  padding: 48px 7%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${device.tablet} {
    padding: 24px 10px;
  }
`;

const Title = styled.h2`
  font-size: 27px;
  text-align: center;
  margin: 0;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.purple[90]};
  line-height: 34px;
`;

const Message = styled.p`
  text-align: center;
  margin: 18px 0 24px;
  color: ${({ theme }) => theme.colors.purple[70]};
`;

const LogoWrapper = styled.div`
  margin-bottom: 70px;

  @media ${device.tablet} {
    margin-bottom: 25px;
  }
`;

const LoginWrapper = styled.div`
  @media ${device.tablet} {
    background-color: ${({ theme }) => theme.colors.white};
    padding: 36px 24px;
    border-radius: 16px;
    box-shadow: 0 4px 48px rgba(0, 0, 0, 0.1);
  }
`;

const AsideContent = styled.aside`
  background-color: ${({ theme }) => theme.colors.orange[50]};
  color: ${({ theme }) => theme.colors.white};
  padding: 120px 7%;

  @media ${device.tablet} {
    display: none;
  }
`;

const AsideTitle = styled.h2`
  font-size: 74px;
  font-weight: 700;
  line-height: 1.1;
  margin: 0 0 38px;
`;

const ManifestoLink = styled.a`
  color: ${({ theme }) => theme.colors.white};
  font-size: 22px;
  font-weight: 700;
`;
//endregion
