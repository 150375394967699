import React, { useEffect, useMemo, useCallback, useState } from "react";
import {
  getTokenFromStorage,
  removeTokenFromStorage,
  saveTokenToStorage,
} from "../utils";

export interface AuthContextValue {
  token: string | null | undefined;
  setToken(token: string): void;
  clearToken(): void;
}

export const AuthContext = React.createContext<AuthContextValue | null>(null);

export const AuthProvider: React.FC<React.PropsWithChildren> = (props) => {
  const [token, setTokenState] = useState<string | null | undefined>(undefined);

  const setToken = useCallback((token: string) => {
    setTokenState(token);
    saveTokenToStorage(token);
  }, []);

  const clearToken = useCallback(() => {
    setTokenState(null);
    removeTokenFromStorage();
  }, []);

  const value = useMemo(() => {
    return { token, setToken, clearToken };
  }, [token, setToken, clearToken]);

  useEffect(() => {
    setTokenState(getTokenFromStorage() ?? null);
  }, []);

  return (
    <AuthContext.Provider value={value}>{props.children}</AuthContext.Provider>
  );
};

export const useAuthContext = (): AuthContextValue => {
  const context = React.useContext(AuthContext);

  if (context == null) {
    throw new Error("Authentication provider is missing");
  }

  return context;
};
