import React from "react";
import Image from "next/image";
import duckIcon from "./images/duck.svg";

interface DuckIconProps {
  width?: number;
}

export function DuckIcon({ width }: DuckIconProps) {
  return <Image src={duckIcon} alt="icon" width={width || 24} />;
}
