import React from "react";
import { DSButton } from "@hundred5/design-system";
import { Icon } from "../icon";
import { faPenToSquare } from "@fortawesome/pro-regular-svg-icons";

interface EditButtonProps {
  onClick: () => void;
}

export function EditButton({ onClick }: EditButtonProps) {
  return (
    <DSButton
      variant="secondary"
      onClick={onClick}
      Icon={<Icon icon={faPenToSquare} color="purple-100" fontSize="12px" />}
    />
  );
}
