import React, { useMemo } from "react";
import { useField } from "formik";
import { uniqueId } from "lodash";
import { DSCombobox, DSField } from "@hundred5/design-system";

interface FormikComboboxProps {
  name: string;
  items: any[];
  labelField: string;
  filterBy: string;
  groupBy?: string;
  label?: React.ReactNode;
  required?: boolean;
  id?: string;
  placeholder?: string;
  disabled?: boolean;
  maxDropdownHeight?: string;
  errorAbsolute?: boolean;
  onChange?: (item?: any) => void;
}

export function FormikCombobox(props: FormikComboboxProps) {
  const [field, meta, helpers] = useField<any>(props.name);
  const error = meta.touched && !!meta.error ? meta.error : null;
  const id = useMemo(() => props.id || uniqueId("formik-field-"), [props.id]);

  return (
    <DSField
      for={id}
      label={props.label}
      required={props.required}
      error={error}
      errorAbsolute={props.errorAbsolute}
    >
      <DSCombobox
        {...field}
        items={props.items}
        selectedItemId={field.value}
        labelField={props.labelField}
        filterBy={props.filterBy}
        groupBy={props.groupBy}
        placeholder={props.placeholder}
        disabled={props.disabled}
        error={!!error}
        maxDropdownHeight={props.maxDropdownHeight}
        onChange={(item) => {
          if (props.onChange) {
            props.onChange(item);
          } else {
            helpers.setValue(item);
          }
        }}
      />
    </DSField>
  );
}
