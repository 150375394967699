import React from "react";
import styled from "@emotion/styled";
import { device } from "@/styles/breakpoints";

export function PageContent({ children }: React.PropsWithChildren) {
  return (
    <Container>
      <Content>{children}</Content>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  justify-content: center;
`;

const Content = styled.div`
  width: 100%;
  max-width: 1200px;
  padding: 24px;

  @media ${device.tablet} {
    padding: 24px 10px;
  }
`;
