import { useCallback } from "react";
import { CombinedError } from "urql";
import * as Sentry from "@sentry/nextjs";
import { useFlashMessages } from "@/features/common";
import { useLogout } from "@/features/auth";

export function useApiErrorHandler() {
  const { addMessage } = useFlashMessages();
  const logOut = useLogout();

  return useCallback(
    (error: CombinedError) => {
      if (error.networkError != null) {
        addMessage({ type: "network_error" });
      }

      for (const gqlError of error.graphQLErrors) {
        const kind = (gqlError.extensions?.kind as string) ?? "Unknown";
        const publicMessage = gqlError.extensions?.publicMessage as string;
        const requestId = gqlError.extensions?.requestID as string;

        if (kind === "invalid") {
          addMessage({
            type: "validation_error",
            error: publicMessage ?? gqlError.message,
          });
        } else if (kind === "unauthorized") {
          addMessage({
            type: "unauthorized",
            error: publicMessage ?? gqlError.message,
          });
          logOut();
        } else {
          addMessage({
            type: "api_error",
            error: publicMessage ?? gqlError.message,
            requestId: requestId,
          });
        }

        const errorMessage =
          publicMessage ?? gqlError.message
            ? `${kind}: ${publicMessage ?? gqlError.message}`
            : kind;
        const errorToLog = new Error(errorMessage);

        // Fingerprint is needed to make Sentry not group errors with different messages into the same issue.
        // https://docs.sentry.io/platforms/javascript/usage/sdk-fingerprinting/
        const fingerprint = ["{{ default }}", errorMessage];

        Sentry.captureException(errorToLog, {
          extra: {
            kind,
            requestId,
            publicMessage,
            message: gqlError.message,
            path: gqlError.path,
          },
          fingerprint,
        });
      }

      return null;
    },
    [addMessage]
  );
}
