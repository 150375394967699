import React from "react";
import styled from "@emotion/styled";
import { Form, Formik } from "formik";
import { array, object, string } from "yup";
import { faArrowRight } from "@fortawesome/pro-regular-svg-icons";
import { getTimeZones } from "@vvo/tzdb";
import { DSButton } from "@hundred5/design-system";
import {
  FormikCheckbox,
  FormikCombobox,
  FormikComboboxMultiple,
  Icon,
  useCountryList,
  useWizard,
} from "@/features/common";
import { IJobOfferWizard } from "@/features/job-offer";
import { StepContent, StepFooter, StepHeader } from ".";

const validationSchema = object().shape({
  countryCodes: array().of(string()).min(1, "This field is mandatory"),
  timezoneName: string()
    .when("remote", {
      is: true,
      then: (schema) => schema.required("This field is mandatory"),
    })
    .nullable(),
});

export function WizardStepLocation() {
  const { data, setData, goForward } = useWizard<IJobOfferWizard>();
  const { countries } = useCountryList();
  const timeZones = getTimeZones();

  const handleForward = (values: any) => {
    setData({ ...data, ...values });
    goForward();
  };

  return (
    <Formik
      onSubmit={handleForward}
      initialValues={{
        countryCodes: data.countryCodes,
        remote: data.remote,
        timezoneName: data.timezoneName,
        timezoneOffset: data.timezoneOffset,
      }}
      validationSchema={validationSchema}
      enableReinitialize={true}
      validateOnMount={true}
    >
      {({ values, setValues, isValid, isSubmitting }) => (
        <Form data-recording-ignore="mask">
          <StepHeader title="Where do you want to work from?" />

          <LocationStepContent>
            <FormikComboboxMultiple
              label="Country (max 5)"
              name="countryCodes"
              placeholder="Type to search..."
              maxSelectedItemCount={5}
              required
              items={countries.map((country) => ({
                id: country.code,
                label: country.name,
              }))}
              maxDropdownHeight="152px"
            />
            <FormikCheckbox
              label="I want to work remotely"
              name="remote"
              onChange={() => {
                setValues(
                  {
                    ...values,
                    remote: !values.remote,
                    timezoneName: null,
                    timezoneOffset: null,
                  },
                  true
                );
              }}
            />

            {values.remote && (
              <>
                <Subtitle>What time zone are you in?</Subtitle>
                <FormikCombobox
                  label="Time zone"
                  name="timezoneName"
                  placeholder="Type to search..."
                  required
                  items={timeZones.map((timezone) => ({
                    id: timezone.name,
                    label: `GMT${timezone.rawFormat}`,
                    ...timezone,
                  }))}
                  labelField="label"
                  filterBy="label"
                  onChange={(timezone) => {
                    setValues(
                      {
                        ...values,
                        timezoneName: timezone.name,
                        timezoneOffset: timezone.rawOffsetInMinutes,
                      },
                      true
                    );
                  }}
                  maxDropdownHeight="152px"
                />
              </>
            )}
          </LocationStepContent>

          <StepFooter>
            <DSButton type="submit" disabled={!isValid || isSubmitting}>
              Next <Icon icon={faArrowRight} color="white" />
            </DSButton>
          </StepFooter>
        </Form>
      )}
    </Formik>
  );
}

const LocationStepContent = styled(StepContent)`
  display: flex;
  flex-direction: column;
  gap: 16px;

  // TODO: ?
  // overflow-y: auto;
`;

const Subtitle = styled.h2`
  margin: 8px 0 0;
  font-size: 14px;
  font-weight: 400;
  color: ${(props) => props.theme.typography.colorPrimary};
`;
