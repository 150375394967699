import React from "react";
import { Icon } from "@/features/common";
import { faCircleQuestion } from "@fortawesome/pro-regular-svg-icons";
import { IconButton } from "./icon-button";

const SUPPORT_LINK =
  "https://support.hire.toggl.com/en/collections/3996598-candidate-portal";

export function SupportIconButton() {
  return (
    <IconButton
      data-sidebar-tooltip="Advice and answers"
      data-sidebar-tooltip-at="right"
      onClick={() => window.open(SUPPORT_LINK, "_blank")}
    >
      <Icon icon={faCircleQuestion} color="purple-40" fontSize="16px" />
    </IconButton>
  );
}
