import React from "react";
import styled from "@emotion/styled";
import { useRouter } from "next/router";
import { useMedia } from "react-use";
import { DSButton } from "@hundred5/design-system";
import { useAmplitude } from "@/features/analytics";
import { useLayout } from "@/features/layout";
import { device } from "@/styles/breakpoints";
import { useLayoutSidebarCompleteProfileQuery } from "@/generated/graphql";

/**
 * Important -> If all details are filled, no component is returned
 */

export function LayoutSidebarCompleteProfile() {
  const router = useRouter();
  const isTablet = useMedia(device.tablet);
  const { setSidebarCollapsed } = useLayout();
  const { logEvent } = useAmplitude();
  const [{ data }] = useLayoutSidebarCompleteProfileQuery();
  const incompleteProfile =
    !!data?.me && (!data.me.email || !data.me.firstName || !data.me.lastName);

  const handleClick = () => {
    logEvent("sidebar/edit details");
    if (isTablet) setSidebarCollapsed(true);
    router.push(`/${data?.me.slug}`);
  };

  return incompleteProfile ? (
    <LayoutSidebarCompleteProfileContainer>
      <Title>Complete your profile</Title>
      Fill in your contact details, to make sure recruiters can get back to you
      <DSButton variant="secondary" onClick={handleClick}>
        Edit details
      </DSButton>
    </LayoutSidebarCompleteProfileContainer>
  ) : null;
}

const LayoutSidebarCompleteProfileContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 12px;
  border-radius: 8px;
  font-weight: 400;
  font-size: 14px;
  color: ${(props) => props.theme.colors.peach[70]};
  background: ${(props) => props.theme.colors.purple[90]};
  box-shadow: ${(props) => props.theme.shadow.card};
  margin: 0 16px 24px;

  button {
    width: 100%;
  }
`;

const Title = styled.div`
  font-weight: 700;
  font-size: 12px;
`;
