import * as React from "react";
import Head from "next/head";
import Image from "next/image";
import styled from "@emotion/styled";
import { PageTitle } from "@/features/common";
import illustration from "./ui/illustration.svg";
import { device } from "@/styles/breakpoints";

interface Props {
  message: React.ReactNode;
  content?: React.ReactNode;
}

export function ErrorPage({ message, content }: Props) {
  return (
    <Container>
      <Head>
        <meta name="robots" content="noindex" />
      </Head>
      <PageTitle />

      <Content>
        <Illustration>
          <Image src={illustration} alt="illustration" />
        </Illustration>
        <ErrorMessage>{message}</ErrorMessage>
        {content}
      </Content>
    </Container>
  );
}

//region Styles
const Container = styled.main`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: var(--view-height);
  position: relative;
`;

const Content = styled.div`
  width: ${(10 / 24) * 100}%;
  padding: 24px 10px;

  @media ${device.tablet} {
    width: 100%;
  }
`;

const Illustration = styled.div`
  margin-bottom: 20px;
  text-align: center;
`;

const ErrorMessage = styled.h1`
  margin: 20px 0;
  padding: 0 20px;
  font-size: ${22 / 14}rem;
  text-align: center;
`;
//endregion
