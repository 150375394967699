import React from "react";
import { Form, Formik } from "formik";
import { object, string } from "yup";
import { faArrowLeft, faArrowRight } from "@fortawesome/pro-regular-svg-icons";
import styled from "@emotion/styled";
import { device } from "@/styles/breakpoints";
import { DSButton } from "@hundred5/design-system";
import {
  FormikButtonGroup,
  FormikInput,
  Icon,
  useWizard,
} from "@/features/common";
import {
  IJobOfferWizard,
  jobOfferSalaryCurrencyList,
  salaryNumberToString,
  salaryStringToNumber,
} from "@/features/job-offer";
import { StepHeader, StepContent, StepFooter } from ".";

const validationSchema = object().shape({
  salary: string().required("This field is mandatory"),
  salaryCurrency: string().trim().required("This field is mandatory"),
});

export function WizardStepSalary() {
  const { data, setData, goBack, goForward } = useWizard<IJobOfferWizard>();

  const handleForward = (values: any) => {
    setData({
      ...data,
      ...values,
      salary: salaryStringToNumber(values.salary),
    });
    goForward();
  };

  return (
    <Formik
      onSubmit={handleForward}
      initialValues={{
        salary: salaryNumberToString(data.salary),
        salaryCurrency: data.salaryCurrency,
      }}
      validationSchema={validationSchema}
      enableReinitialize={true}
      validateOnMount={true}
    >
      {({ isValid, setFieldValue, isSubmitting }) => (
        <Form data-recording-ignore="mask">
          <StepHeader title="What is your expected annual salary?" />

          <SalaryStepContent>
            <FieldRow>
              <FormikInput
                name="salary"
                label="Salary"
                required
                placeholder={salaryNumberToString(25000)}
                onChange={(e) => {
                  const value = salaryStringToNumber(e.target.value);
                  setFieldValue("salary", salaryNumberToString(value));
                }}
              />
              <ButtonGroupWrapper>
                <FormikButtonGroup
                  name="salaryCurrency"
                  items={jobOfferSalaryCurrencyList}
                />
              </ButtonGroupWrapper>
            </FieldRow>

            <Info>
              Your salary info stays private! We only use it to filter roles for
              you. When in doubt, opt for a lower amount to avoid missing out on
              great opportunities.
            </Info>
          </SalaryStepContent>

          <StepFooter>
            <DSButton variant="secondary" onClick={goBack}>
              <Icon icon={faArrowLeft} />
            </DSButton>
            <DSButton type="submit" disabled={!isValid || isSubmitting}>
              Next <Icon icon={faArrowRight} color="white" />
            </DSButton>
          </StepFooter>
        </Form>
      )}
    </Formik>
  );
}

const SalaryStepContent = styled(StepContent)`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const FieldRow = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr min-content;
  gap: 8px;
  @media ${device.tablet} {
    grid-auto-flow: row;
    grid-auto-columns: 1fr;
    gap: 16px;
  }
`;

const ButtonGroupWrapper = styled.div`
  padding-top: 19px; // height of the field label
  @media ${device.tablet} {
    padding-top: 0;
  }
`;

const Info = styled.div`
  border-radius: 8px;
  background: ${(props) => props.theme.colors.purple[5]};
  padding: 12px;
  font-size: 12px;
  font-weight: 400;
  color: ${(props) => props.theme.typography.colorPrimary};
`;
