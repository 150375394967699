import React, { ReactNode } from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { useColor } from "@/features/common";

interface ProgressCircleProps {
  /**
   * Percentual value of how much the progress circle is filled out of 100%
   */
  value: number;
  /**
   * Color of the filled progress circle.
   * If color from theme is used, then format -> $name-$value
   */
  color?: string;
  /**
   * Circle thickness
   */
  thickness?: number;
  /**
   * Circle size
   */
  size?: number;
  /**
   * Rotated the fill
   */
  isRotated?: boolean;
  /**
   * Icon
   */
  children?: ReactNode;
}

export const ProgressCircle = ({
  value,
  color,
  thickness = 30,
  size = 166,
  isRotated = false,
  children,
}: ProgressCircleProps) => {
  const fillColor = useColor(color, "purple-100");

  const center = size / 2;
  const radius = (size - thickness) / 2;

  const angle = (Math.min(value, 100) / 100) * 2 * Math.PI;
  const start = getCirclePosition(center, radius, 0).join(",");
  const middle = getCirclePosition(center, radius, angle / 2).join(",");
  const end = getCirclePosition(center, radius, angle).join(",");
  const largeArc = angle / 2 > Math.PI ? "1" : "0";

  // the filled-in part of the circle needs to be split into two arcs, as it is
  // not possible to draw a full 360 circle using a single arc
  const path = [
    `M ${start}`, // move to the top of the circle
    `A ${radius} ${radius} 0 ${largeArc} 1 ${middle}`, // draw the first half of the circle
    `A ${radius} ${radius} 0 ${largeArc} 1 ${end}`, // draw the second halft
  ].join(" ");

  return (
    <Container size={size}>
      <Image width={size} height={size} isRotated={isRotated}>
        <circle
          cx={center}
          cy={center}
          r={radius}
          fill="none"
          strokeWidth={thickness}
        />

        <path d={path} fill="none" stroke={fillColor} strokeWidth={thickness} />
      </Image>
      <Text size={size} color={fillColor}>
        {children ? children : value + "%"}
      </Text>
    </Container>
  );
};

const Container = styled.div<{ size: number }>`
  position: relative;
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
`;

const Image = styled.svg<{ isRotated: boolean }>`
  display: block;
  ${(props) =>
    props.isRotated &&
    css`
      transform: rotate(180deg);
      transform-origin: center;
    `}

  & > circle {
    stroke: ${(props) => props.theme.colors.purple[5]};
  }
`;

const Text = styled.div<{ size: number; color: string }>`
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  color: ${(props) => props.color};
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 24px;
`;

const getCirclePosition = (
  center: number,
  radius: number,
  angle: number
): [number, number] => {
  return [
    center + radius * Math.sin(angle),
    center + radius * -Math.cos(angle),
  ];
};
