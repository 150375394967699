import React from "react";
import styled from "@emotion/styled";
import { device } from "@/styles/breakpoints";

interface EmailButtonProps {
  title: string;
  email: string;
}

export function EmailButton({ title, email }: EmailButtonProps) {
  const handleRedirect = () => {
    window.location.href = "mailTo:" + email;
  };

  return <EButton onClick={handleRedirect}>{title}</EButton>;
}

const EButton = styled.button`
  border-radius: 32px;
  padding: 6px 28px;
  height: 34px;
  background-color: ${(props) => props.theme.colors.orange[100]};
  color: ${(props) => props.theme.colors.orange[10]};
  border: 0;
  font-family: "GT Haptik";
  font-size: 14px;
  font-weight: 700;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  transition: font-style 0.1s;

  &:hover {
    font-style: italic;
    opacity: 0.7;
  }

  @media ${device.tablet} {
    width: 100%;
  }
`;
