import React, { useState } from "react";
import styled from "@emotion/styled";
import Image from "next/image";
import { useMedia } from "react-use";
import { DSButton } from "@hundred5/design-system";
import { device } from "@/styles/breakpoints";
import {
  JobOfferWizardModal,
  useJobOfferEnableProgress,
} from "@/features/job-offer";
import discoverImg from "./images/discover.svg";

/**
 * Important -> If less than three skills are assessed then, no component is returned
 */

export function JobOfferBanner() {
  const isTablet = useMedia(device.tablet);
  const { assessedSkillCount, jobOffersEnabled } = useJobOfferEnableProgress();
  const [openWizardModal, setOpenWizardModal] = useState(false);

  return (
    <>
      {assessedSkillCount < 3 || jobOffersEnabled ? null : (
        <JobOfferBannerContainer>
          <ImageContainer>
            <Image
              src={discoverImg}
              alt="discover"
              width={isTablet ? 50 : 80}
            />
          </ImageContainer>
          <Title>Get discovered by companies</Title>
          <Description>
            Want to receive personalized job matches? Opt in to Job Offers.
          </Description>
          <Action>
            <DSButton
              variant="secondary"
              size={isTablet ? "small" : "big"}
              onClick={() => setOpenWizardModal(true)}
            >
              Enable Job Offers
            </DSButton>
          </Action>
        </JobOfferBannerContainer>
      )}

      <JobOfferWizardModal
        isOpen={openWizardModal}
        onClose={() => setOpenWizardModal(false)}
      />
    </>
  );
}

const JobOfferBannerContainer = styled.div`
  padding: 16px;
  margin-bottom: 24px;
  border-radius: 8px;
  background-color: ${(props) => props.theme.colors.white};
  display: grid;
  grid-template-areas: "image title action" "image description action";
  grid-auto-columns: min-content minmax(0, 1fr) min-content;
  grid-column-gap: 32px;
  grid-row-gap: 4px;
  align-items: center;

  @media ${device.tablet} {
    grid-template-areas: "image title" "image description" ". action";
    grid-column-gap: 16px;
    grid-row-gap: 2px;
  }
`;

const ImageContainer = styled.div`
  grid-area: image;
  display: flex;
`;

const Title = styled.div`
  grid-area: title;
  align-self: end;
  font-size: 16px;
  font-weight: 700;
  color: ${(props) => props.theme.typography.colorPrimary};

  @media ${device.tablet} {
    font-size: 14px;
  }
`;

const Description = styled.div`
  grid-area: description;
  align-self: start;
  font-size: 14px;
  font-weight: 400;
  color: ${(props) => props.theme.typography.colorSecondary};

  @media ${device.tablet} {
    font-size: 12px;
  }
`;

const Action = styled.div`
  grid-area: action;

  @media ${device.tablet} {
    margin-top: 6px;
  }
`;
