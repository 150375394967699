import React from "react";
import styled from "@emotion/styled";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/pro-regular-svg-icons";
import { Icon } from "../icon";

interface CarouselProps {
  canGoToPrev: boolean;
  canGoToNext: boolean;
  goPrev: () => void;
  goNext: () => void;
  iconSize?: string;
  width?: string;
}

export function Carousel({
  canGoToPrev,
  canGoToNext,
  goPrev,
  goNext,
  iconSize = "12px",
  width = "52px",
}: CarouselProps) {
  return (
    <Container
      width={width}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <IconWrapper onClick={goPrev} disabled={!canGoToPrev}>
        <Icon
          icon={faChevronLeft}
          fontSize={iconSize}
          color={canGoToPrev ? "purple-100" : "purple-20"}
        />
      </IconWrapper>
      <IconWrapper onClick={goNext} disabled={!canGoToNext}>
        <Icon
          icon={faChevronRight}
          fontSize={iconSize}
          color={canGoToNext ? "purple-100" : "purple-20"}
        />
      </IconWrapper>
    </Container>
  );
}

const Container = styled.div<{ width: string }>`
  display: flex;
  justify-content: space-between;
  min-width: ${(props) => props.width};
  max-width: ${(props) => props.width};
`;

const IconWrapper = styled.div<{ disabled: boolean }>`
  display: flex;
  cursor: pointer;
  border-radius: 8px;
  width: 24px;
  height: 24px;
  justify-content: center;
  align-items: center;
  pointer-events: ${(props) => (props.disabled ? "none" : "auto")};
  transition: background-color 0.2s;

  svg {
    transform: scale(1);
    transition: transform 0.2s;
  }

  &:hover {
    background-color: ${(props) => props.theme.colors.purple[5]};
    svg {
      transform: scale(0.75);
    }
  }
`;
