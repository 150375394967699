import { store } from "@/features/common";

const STORE_KEY = "token";

export const getTokenFromStorage = () => {
  return store.get(STORE_KEY);
};

export const saveTokenToStorage = (token: string) => {
  store.set(STORE_KEY, token);
};

export const removeTokenFromStorage = () => {
  store.remove(STORE_KEY);
};
