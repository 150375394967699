import React from "react";
import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from "@fortawesome/react-fontawesome";
import { useColor } from "@/features/common";

interface IconProps extends FontAwesomeIconProps {
  /**
   * Color of the icon.
   * If color from theme is used, then format -> $name-$value
   */
  color?: string;
}

export function Icon({ color, ...props }: IconProps) {
  return <FontAwesomeIcon color={useColor(color, "purple-100")} {...props} />;
}
