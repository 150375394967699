import React from "react";
import styled from "@emotion/styled";
import { DSButton } from "@hundred5/design-system";

const FEEDBACK_LINK = "https://forms.gle/jrnY8fAkRL2ecycM6";

export function LayoutSidebarFeedback() {
  return (
    <LayoutSidebarFeedbackContainer>
      Help us get better
      <DSButton
        variant="secondary"
        onClick={() => window.open(FEEDBACK_LINK, "_blank")}
      >
        Give feedback
      </DSButton>
    </LayoutSidebarFeedbackContainer>
  );
}

const LayoutSidebarFeedbackContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 12px 12px 16px;
  border-radius: 8px;
  font-weight: 700;
  font-size: 12px;
  color: ${(props) => props.theme.colors.purple[90]};
  background: ${(props) => props.theme.colors.purple[30]};
  box-shadow: ${(props) => props.theme.shadow.card};
  margin: auto 16px 16px;

  button {
    width: 100%;
  }
`;
