import React from "react";
import styled from "@emotion/styled";
import { device } from "@/styles/breakpoints";
import { useRouter } from "next/router";
import { BackButton } from "../back-button";

export function NavigationHeader({ children }: React.PropsWithChildren) {
  const router = useRouter();

  return (
    <Container>
      <BackButton onClick={() => router.push("/")} />
      {children}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 12px 36px;
  font-weight: 700;
  font-size: 22px;
  color: ${(props) => props.theme.typography.colorPrimary};
  background: ${(props) => props.theme.colors.white};

  @media ${device.tablet} {
    border-bottom: 1px solid ${(props) => props.theme.colors.purple[10]};
    padding: 16px;
    font-size: 16px;
  }
`;
