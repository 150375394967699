import styled from "@emotion/styled";
import React from "react";

interface ToggleSwitchProps {
  label: string;
  checked: boolean;
  onChange: (checked: boolean) => void;
}

export function ToggleSwitch({ label, checked, onChange }: ToggleSwitchProps) {
  return (
    <SwitchLabel>
      <SwitchCheckbox
        type="checkbox"
        checked={checked}
        onChange={(e) => onChange(e.target.checked)}
      />
      <Slider />
      {label}
    </SwitchLabel>
  );
}

const SwitchLabel = styled.label`
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: 500;
  font-size: 14px;
  color: ${(props) => props.theme.typography.colorPrimary};
`;

const Slider = styled.span`
  position: relative;
  width: 28px;
  height: 10px;
  border-radius: 5px;
  background-color: ${(props) => props.theme.colors.purple[20]};
  transition: background-color 0.2s;
  flex-shrink: 0;

  &:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 0;
    top: calc(50% - 8px);
    border-radius: 16px;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.4);
    background-color: ${(props) => props.theme.colors.purple[60]};
    transition: background-color 0.2s, transform 0.2s;
  }
`;

const SwitchCheckbox = styled.input`
  position: absolute;
  visibility: hidden;
  pointer-events: none;
  width: 0;
  height: 0;

  &:checked + ${Slider} {
    background-color: ${(props) => props.theme.colors.orange[20]};
  }

  &:checked + ${Slider}:before {
    transform: translateX(12px);
    background-color: ${(props) => props.theme.colors.orange[100]};
  }
`;
