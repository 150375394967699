import { Global, css, useTheme } from "@emotion/react";

export function GlobalStyles() {
  const theme = useTheme();
  return (
    <Global
      styles={css`
        *,
        *::after,
        *::before {
          box-sizing: border-box;
        }

        html {
          line-height: 1.5;
          font-size: 14px;
          font-family: "Inter", sans-serif;
          color: ${theme.typography.colorPrimary};
          background-color: ${theme.colors.purple[10]};
        }

        body {
          margin: 0;
          padding: 0;
        }

        a {
          cursor: pointer;
          text-decoration: none;
          color: ${theme.typography.colorAccent};
        }

        button,
        select,
        input,
        textarea {
          line-height: 1.5;
          font-family: "Inter", sans-serif;
        }
      `}
    />
  );
}
