import React from "react";
import styled from "@emotion/styled";
import { Carousel, useCarousel } from "../carousel";
import { Tabs } from "../tabs";
import { device } from "@/styles/breakpoints";

interface TabsWithCarouselProps {
  tabs?: string[];
  activeTab: string;
  onTabClick: (tab: string) => void;
  slideStep: number;
}

export function TabsWithCarousel({
  tabs,
  activeTab,
  onTabClick,
  slideStep,
}: TabsWithCarouselProps) {
  const {
    containerRef,
    contentRef,
    goNext,
    goPrev,
    canGoNext,
    canGoPrev,
    offset,
  } = useCarousel({ slideStep: slideStep });

  return (
    <Wrapper>
      <TabsContainer ref={containerRef}>
        <TabsContent
          style={offset}
          ref={contentRef}
          tabs={tabs}
          activeTab={activeTab}
          onTabClick={onTabClick}
        />
      </TabsContainer>
      {(canGoPrev || canGoNext) && (
        <CarouselContainer>
          <Carousel
            canGoToPrev={canGoPrev}
            canGoToNext={canGoNext}
            goPrev={goPrev}
            goNext={goNext}
          />
        </CarouselContainer>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
`;

const TabsContainer = styled.div`
  overflow: hidden;
  margin: 0 -10px;
`;

const TabsContent = styled(Tabs)`
  position: relative;
  width: max-content;
  margin: 0;

  @media ${device.tablet} {
    overflow-x: scroll;
    width: auto;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    &::-webkit-scrollbar {
      display: none; /* Chrome, Safari and Opera */
    }
  }
`;

const CarouselContainer = styled.div`
  height: 30px;

  @media ${device.tablet} {
    display: none;
  }
`;
