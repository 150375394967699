import React from "react";
import styled from "@emotion/styled";

interface NotificationBadgeProps {
  /**
   * Count of the notifications (hiden when <= 0)
   */
  count: number;
  /**
   * Absolute position of the badge
   */
  position?: {
    top?: string;
    right?: string;
    bottom?: string;
    left?: string;
  };
}

export function NotificationBadge({ count, position }: NotificationBadgeProps) {
  return count > 0 ? <Badge position={position}>{count}</Badge> : null;
}

const Badge = styled.span<{
  position?: {
    top?: string;
    right?: string;
    bottom?: string;
    left?: string;
  };
}>`
  background: ${(props) => props.theme.colors.red[100]};
  color: ${(props) => props.theme.colors.white};
  font-weight: 500;
  font-size: 10px;
  border-radius: 50%;
  min-width: 14px;
  height: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  ${(props) => props.position?.top && `top: ${props.position.top};`}
  ${(props) => props.position?.right && `right: ${props.position.right};`}
  ${(props) => props.position?.bottom && `bottom: ${props.position.bottom};`}
  ${(props) => props.position?.left && `left: ${props.position.left};`}
`;
