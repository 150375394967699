import { LoginProviders, useAuthContext } from "@/features/auth";
import { NextRouter, useRouter } from "next/router";
import { useCallback } from "react";

//region Hooks
export const useLogIn = () => {
  const router = useRouter();

  return (method: LoginProviders, email?: string): Promise<void> => {
    switch (method) {
      case "linkedin":
        void logInWithLinkedIn(router);
        break;
      case "github":
        void logInWithGithub(router);
        break;
      case "test":
        if (email) void logInWithTestAuth(router, email);
        break;
    }

    return new Promise(() => {});
  };
};

export const useLoggedIn = (): boolean | null => {
  const context = useAuthContext();

  if (context.token === undefined) return null;

  return context.token != null;
};

export const useLogout = () => {
  const context = useAuthContext();

  return useCallback(() => {
    context.clearToken();
  }, [context]);
};
//endregion

//region Provider functions
const logInWithGithub = (router: NextRouter) => {
  const url = `${process.env.NEXT_PUBLIC_API_URL}/candidate_portal/api/auth/github`;
  return router.push(url);
};

const logInWithLinkedIn = (router: NextRouter) => {
  const url = `${process.env.NEXT_PUBLIC_API_URL}/candidate_portal/api/auth/linkedin`;
  return router.push(url);
};

const logInWithTestAuth = (router: NextRouter, email: string) => {
  const query = new URLSearchParams({ email: email });
  const url = `${process.env.NEXT_PUBLIC_API_URL}/candidate_portal/api/auth/test?${query}`;
  return router.push(url);
};
//endregion
