import React from "react";
import {
  Application,
  useLayoutSidebarApplicationListQuery,
} from "@/generated/graphql";
import { LayoutSidebarApplicationListView } from "./layout-sidebar-application-list.view";

interface LayoutSidebarApplicationListProps {
  /**
   * Load active or past applications
   */
  type: "active" | "past";
}

export function LayoutSidebarApplicationList({
  type,
}: LayoutSidebarApplicationListProps) {
  const [{ data }] = useLayoutSidebarApplicationListQuery({
    variables: { isOpen: type === "active" },
  });

  return (
    <LayoutSidebarApplicationListView
      type={type}
      applications={data?.applications as Partial<Application>[]}
    />
  );
}
