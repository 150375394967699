import React from "react";
import styled from "@emotion/styled";
import { Form, Formik } from "formik";
import { object, string } from "yup";
import {
  faArrowLeft,
  faCircleQuestion,
} from "@fortawesome/pro-regular-svg-icons";
import { DSButton } from "@hundred5/design-system";
import { FormikInput, Icon, useWizard } from "@/features/common";
import { IJobOfferWizard } from "@/features/job-offer";
import { StepContent, StepFooter, StepHeader } from ".";

const validationSchema = object().shape({
  firstName: string().trim().required("This field is mandatory"),
  lastName: string().trim().required("This field is mandatory"),
  contactEmail: string()
    .trim()
    .required("This field is mandataory")
    .email("Enter a valid email"),
  linkedin: string()
    .trim()
    .matches(
      /^https:\/\/([a-z]{2,3}\.)?linkedin\.com/,
      "Invalid URL format. Example: https://www.linkedin.com/in/user-name-123"
    ),
});

export function WizardStepContactDetails() {
  const { data, goBack, goForward, onSubmit } = useWizard<IJobOfferWizard>();

  const handleSubmit = async (values: any) => {
    const result = await onSubmit({ ...data, ...values });
    if (result) goForward();
  };

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={{
        firstName: data.firstName,
        lastName: data.lastName,
        contactEmail: data.contactEmail,
        linkedin: data.linkedin,
      }}
      validationSchema={validationSchema}
      enableReinitialize={true}
      validateOnMount={true}
    >
      {({ isValid, isSubmitting }) => (
        <Form data-recording-ignore="mask">
          <StepHeader title="Review your contact details" />

          <ContactDetailsStepContent>
            <FormikInput label="First name" name="firstName" required />
            <FormikInput label="Last name" name="lastName" required />
            <FormikInput
              label={
                <LabelWithIcon>
                  E-mail*
                  <Icon
                    icon={faCircleQuestion}
                    color="purple-60"
                    fontSize="12px"
                    data-rh="Job notifications will be sent to this email. It might be different from the one you use to log in."
                    data-rh-at="right"
                  />
                </LabelWithIcon>
              }
              name="contactEmail"
            />
            <FormikInput label="LinkedIn profile link" name="linkedin" />
          </ContactDetailsStepContent>

          <StepFooter>
            <DSButton variant="secondary" onClick={goBack}>
              <Icon icon={faArrowLeft} />
            </DSButton>
            <DSButton type="submit" disabled={!isValid || isSubmitting}>
              Submit
            </DSButton>
          </StepFooter>
        </Form>
      )}
    </Formik>
  );
}

const ContactDetailsStepContent = styled(StepContent)`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const LabelWithIcon = styled.span`
  display: flex;
  align-items: center;
  gap: 4px;
`;
