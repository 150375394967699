import React from "react";
import styled from "@emotion/styled";
import { useMedia } from "react-use";
import { device } from "@/styles/breakpoints";
import { faCircleInfo } from "@fortawesome/pro-regular-svg-icons";
import { CandidateSkill, CandidateSkillAssessment } from "@/generated/graphql";
import { Icon, ProgressBar } from "@/features/common";
import { SkillAssessmentStartButton } from "@/features/skill";
import { LinkedInAddToProfileButton } from ".";

interface SkillListItemProps {
  skill: CandidateSkill;
  candidateSlug: string;
}

export function SkillListItem({ skill, candidateSlug }: SkillListItemProps) {
  const isTablet = useMedia(device.tablet);

  return (
    <SkillListItemContainer>
      <Name data-recording-sensitive>{skill.name}</Name>
      <Progress>
        <ProgressBar value={skill.score} color="orange-100" />
        <Score>{skill.score}%</Score>
      </Progress>
      <Questions>{skill.questionCount} questions</Questions>
      {!isTablet && skill.isAssessed && (
        <LinkedIn>
          <LinkedInAddToProfileButton
            skill={skill}
            candidateSlug={candidateSlug}
          />
        </LinkedIn>
      )}
      <TestAction>
        {getSkillAssessmentAction(
          skill.id,
          skill.name,
          skill.assessment,
          skill.isAssessed
        )}
        {isTablet && skill.isAssessed && (
          <LinkedInAddToProfileButton
            skill={skill}
            candidateSlug={candidateSlug}
          />
        )}
      </TestAction>
    </SkillListItemContainer>
  );
}

const getSkillAssessmentAction = (
  id: string,
  name: string,
  assessment: CandidateSkillAssessment | null,
  isAssessed: boolean
) => {
  const isTablet = useMedia(device.tablet);
  if (isAssessed && !assessment) {
    return <Description>Completed</Description>;
  }

  if (!assessment) {
    return <Description>Coming soon!</Description>;
  }

  if (assessment.remainingRetakes === 0) {
    return (
      <Description>
        Testing limit reached
        {!isTablet && (
          <Icon
            icon={faCircleInfo}
            color="purple-60"
            data-rh="You have already taken this skill test the maximum of three times."
            data-rh-at="top"
          />
        )}
      </Description>
    );
  }

  if (assessment.daysUntilRetake > 0) {
    return (
      <Description>
        {`Test again in ${assessment.daysUntilRetake} day${
          assessment.daysUntilRetake > 1 ? "s" : ""
        }`}
        {!isTablet && (
          <Icon
            icon={faCircleInfo}
            color="purple-60"
            data-rh={`You have ${assessment.remainingRetakes} attempt${
              assessment.remainingRetakes > 1 ? "s" : ""
            } remaining!`}
            data-rh-at="top"
          />
        )}
      </Description>
    );
  }

  if (assessment.daysUntilRetake === 0) {
    return (
      <SkillAssessmentStartButton
        openingSlug={assessment.openingSlug}
        testSlug={assessment.testSlug}
        skillId={id}
        skillName={name}
        retake
      />
    );
  }

  return null;
};

const SkillListItemContainer = styled.div`
  display: grid;
  grid-template-areas: "name progress questions linkedIn action";
  grid-auto-columns: minmax(0, 1fr);
  grid-auto-rows: min-content;
  grid-column-gap: 16px;
  grid-row-gap: 4px;
  font-weight: 400;
  font-size: 14px;
  align-content: center;
  border-bottom: 1px solid ${(props) => props.theme.colors.purple[10]};
  padding: 16px 16px 16px 0;

  &:last-of-type {
    border-bottom: none;
  }

  @media ${device.tablet} {
    grid-template-areas: "name action" "questions action" "progress action";
    grid-auto-columns: minmax(0, 1fr) minmax(0, 0.8fr);
    font-size: 12px;
    padding: 16px 8px 16px 0;

    &:first-of-type {
      padding-top: 0;
    }

    &:last-of-type {
      padding-bottom: 0;
    }
  }
`;

const Name = styled.div`
  grid-area: name;
  color: ${(props) => props.theme.typography.colorPrimary};
`;

const Progress = styled.div`
  grid-area: progress;
  display: flex;
  align-items: center;
  gap: 12px;

  @media ${device.tablet} {
    gap: 8px;
  }
`;

const Score = styled.div`
  color: ${(props) => props.theme.typography.colorPrimary};
  min-width: 36px;
  text-align: end;
`;

const Questions = styled.div`
  grid-area: questions;
  justify-self: flex-end;
  align-self: center;
  color: ${(props) => props.theme.typography.colorSecondary};

  @media ${device.tablet} {
    justify-self: flex-start;
  }
`;

const TestAction = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  grid-area: action;
  align-self: center;
  align-items: flex-end;
  margin: -6px 0;
`;

const LinkedIn = styled.div`
  grid-area: linkedIn;
  align-self: center;
  justify-self: flex-end;
  margin: -6px 0;
`;

const Description = styled.span`
  font-style: italic;
  font-weight: 400;
  font-size: 12px;
  color: ${(props) => props.theme.typography.colorSecondary};
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: flex-end;
`;
